import { Box, Typography } from "@mui/material";
import { BillUploaderBox, BillUploaderLoading } from "./whyUsStyles";
import { Description } from "@mui/icons-material";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import { useEffect, useState } from "react";

const FileUploadLoading = ({ startUploading, bill }: { startUploading: boolean; bill: any }) => {
	const colors = useSelector(selectColors);

	const [uploading, setUploading] = useState<number>(0);

	useEffect(() => {
		if (startUploading) {
			setUploading(1);
			// updating the loading by 1% after every 400ms
			setInterval(() => setUploading((state) => (state < 90 ? state + 1 : state)), 400);
		}
	}, [startUploading]);

	return (
		<BillUploaderBox sx={{ border: `1px solid ${colors.border}` }}>
			<BillUploaderLoading sx={{ width: `${uploading}%`, backgroundColor: colors.lightGray }} />
			<Description color="primary" sx={{ height: 47, width: 47 }} />
			<Box textAlign="left">
				<Typography
					variant="h6"
					sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: 440 }}
				>
					{bill?.name || "Bill not selected"}
				</Typography>
				<Typography color={colors.textMid} mt={8}>
					{((bill?.size ?? 0) / (1024 * 1024)).toFixed(2)} MB - {uploading}% uploaded
				</Typography>
			</Box>
		</BillUploaderBox>
	);
};

export default FileUploadLoading;
