import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { BorderedBox } from "./whyUsStyles";
import CustomToggleButton from "../Common/CustomToggleButton";
import { useState } from "react";
import { SolarPowerDayImage, SolarPowerNightImage } from "../../Utils/Images";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";

const timeOptions = {
	DAY: { text: "Day", value: 1 },
	NIGHT: { text: "Night", value: 2 },
};

const DayNightSolar = () => {
	const colors = useSelector(selectColors);
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	const [option, setOption] = useState<number | string>(timeOptions.DAY.value);
	const [applyTransitions, setApplyTransitions] = useState<boolean>(false);

	const handleChangeTab = (val: number | string) => {
		setOption(val);
		setApplyTransitions(true);
		setTimeout(() => setApplyTransitions(false), 120);
		// setTimeout(() => setApplyTransitions(false), 200);
	};

	const isDarkMode = option === timeOptions.NIGHT.value;
	const textSnippets = useSelector(selectAllTextSnippets);

	return (
		<>
			<Typography variant={isSmallScreen ? "h3" : "h2"} mt={{ xs: 24, sm: 56 }} mb={10}>
				{textSnippets["full_credit_excess_energy"] || "Get Full Credit for Excess Energy Production"}
			</Typography>
			<Typography fontSize={16} mb={32}>
				{textSnippets["excess_energy_info"] ||
					"While your system production is guaranteed, any excess electricity produced is yours to keep at no additional charge"}
			</Typography>
			<BorderedBox bgcolor={isDarkMode ? colors.darkBg : "transparent"} sx={{ border: `1px solid ${colors.border}` }}>
				<Box display="flex" justifyContent="center" className="buttons-to-hide-in-pdf">
					<CustomToggleButton
						isDarkMode={isDarkMode}
						value={option}
						onChange={handleChangeTab}
						options={Object.values(timeOptions)}
					/>
				</Box>
				<Typography
					variant="body2"
					mb={11}
					mt={33}
					maxWidth={{ xs: "100%", sm: 400 }}
					color={isDarkMode ? "#ffff" : "auto"}
					minHeight={60}
					style={{
						transition: "all ease 0.01s",
						opacity: applyTransitions ? 0 : 1,
					}}
				>
					{isDarkMode
						? textSnippets["electricity_credits_draw"] ||
						  "When solar production is low such as night or winter you can draw back your electricity credits for free."
						: textSnippets["energy_production_banked"] ||
						  "Excess energy produced by your solar panels e.g. on a very sunny day is banked on the grid for free and stored as electricity credits"}
				</Typography>

				<img
					alt="Solar"
					src={isDarkMode ? SolarPowerNightImage : SolarPowerDayImage}
					width={"100%"}
					style={{
						transition: "all ease-out 0.1s",
						// opacity: applyTransitions ? 0 : 1,
						transform: applyTransitions ? "scale(0.98)" : "scale(1)",
					}}
				/>
			</BorderedBox>
		</>
	);
};

export default DayNightSolar;
