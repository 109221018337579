import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import * as React from "react";

interface Option {
	text: string;
	value: string;
}

interface CustomRadioButtonsGroupProps {
	options: Array<Option>;
	value: string;
	onChange: (value: string) => void;
	label?: string;
	top?: string | number;
	bottom?: string | number;
}

const CustomRadioButtonsGroup = ({ options, value, label, onChange, top, bottom }: CustomRadioButtonsGroupProps) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange?.((event.target as HTMLInputElement).value);
	};

	return (
		<Box sx={{ mt: top, mb: bottom }}>
			<FormControl>
				{!!label && (
					<FormLabel id="demo-radio-buttons-group-label">
						<Typography mb={10} variant="h6" color='inherit'>
							{label}
						</Typography>
					</FormLabel>
				)}
				<RadioGroup
					aria-labelledby="demo-radio-buttons-group-label"
					value={value}
					onChange={handleChange}
					name="radio-buttons-group"
				>
					{options?.map((item: Option) => (
						<FormControlLabel key={item.value} value={item.value} label={item.text} control={<Radio />} />
					))}
				</RadioGroup>
			</FormControl>
		</Box>
	);
};

export default CustomRadioButtonsGroup;
