import SchedulingPageLayout from "../SchedulingPageLayout";
import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import { GrayTextBlock, RoundedImage, TwoColumnGrid } from "../../WhyUs/whyUsStyles";
import { HomeImage } from "../../../Utils/Images";
import { BigIconCircle } from "../schedulingStyles";
import { DoneOutlineRounded } from "@mui/icons-material";
import { useRef } from "react";
import { useSelector } from "../../../Redux/reduxHooks";
import { selectUser } from "../../../Redux/Slices/userSlice";
import { getStreetViewImage } from "../../../Utils/utils";
import { borderRadius } from "../../../Utils/spacings";
import { selectColors } from "../../../Redux/Slices/generalSlice";
import { selectAllTextSnippets } from "../../../Redux/Slices/textSnippetsSlice";

const Activation = () => {
	const colors = useSelector(selectColors)
	const user = useSelector(selectUser);
	const streetViewImage = getStreetViewImage(user.address);
	const rightColumnRef = useRef<HTMLDivElement | null>(null);
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
	const textSnippets = useSelector(selectAllTextSnippets);

	const ourReach = [
		{
			title: "Dear Client,",
			description:
				textSnippets["activation_official_text"] ||
				`It's official - your solar panel has received the green light to go!`,
		},
		{
			title: textSnippets["activation_label"] || "Activate your Solar Panel",
			description:
				textSnippets["activation_harness_text"] ||
				`The moment has arrived to harness the sun's power and activate your solar panels, with all permissions in place, you're just a click away from generating your own clean, renewable energy.`,
		},
		{
			title: textSnippets["activation_monitoring_label"] || "Monitoring and Support",
			description:
				textSnippets["activation_support_text"] ||
				`We're here for you, even after activation. Keep an eye on your system's performance and count on us for any maintenance needs.`,
		},
		{
			title: textSnippets["activation_sustainable_label"] || "Powering your sustainable future",
			description:
				textSnippets["activation_embrace_text"] ||
				`By embracing solar energy, you're not only saving on electricity bills but also championing a greener more sustainable tomorrow.`,
		},
		{
			title: textSnippets["activation_thank_you_label"] || "Thank You For Choosing Us",
			description:
				textSnippets["activation_commitment_text"] ||
				`Our commitment to a brighter future is commendable. Thank you for letting us be a part of your solar journey. Ready to shine? simply activate, and let the sun do the rest!`,
		},
		{
			title: textSnippets["activation_best_regards_label"] || "Best Regards",
			description: textSnippets["activation_sungroup_text"] || `Your Sun group energy team.`,
		},
	];
	console.log("SDF", streetViewImage);

	return (
		<SchedulingPageLayout>
			<TwoColumnGrid my={48} gridTemplateColumns={{ xs: "1fr", sm: "1.5fr 1fr" }} alignItems="stretch" gap={32}>
				<RoundedImage
					src={streetViewImage || HomeImage}
					style={{ flexGrow: 1, borderRadius: borderRadius.sm }}
					alt=""
				/>

				<Box ref={rightColumnRef} height="100%">
					<GrayTextBlock sx={{ p: "25px 18px", backgroundColor: colors.lightGray, gap: 24 }}>
						<BigIconCircle sx={{ width: 55, height: 55, fontSize: 32, mx: "auto", backgroundColor: "success.dark" }}>
							<DoneOutlineRounded fontSize="inherit" />
						</BigIconCircle>

						<Typography variant={isSmallScreen ? "h5" : "h4"} mt={-8} textAlign="center">
							Congratulations! Your solar panels are ready to shine!
						</Typography>

						<Divider />

						{ourReach.map((sol, idx) => (
							<Box key={idx}>
								<Typography variant="h5" fontSize={18} mb={8}>
									{!idx ? sol.title?.replace("Client", user.name) : sol.title}
								</Typography>
								<Typography fontSize={16}>{sol.description} </Typography>
							</Box>
						))}
					</GrayTextBlock>
				</Box>
			</TwoColumnGrid>
		</SchedulingPageLayout>
	);
};
export default Activation;
