import { FormEvent, useEffect, useState } from "react";
import { UserState } from "../../Redux/Slices/userSlice";
import CustomButton from "../Common/CustomButton";
import CustomTextField, { Asterisk } from "../Common/CustomTextField";
import { Box, Divider, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import ImageUploader from "../Common/ImageUploader";
import * as EmailValidator from "email-validator";
import GoogleMapsTextField, { PlaceType } from "../Common/GoogleMapsTextField";

import MuiPhoneNumber from "material-ui-phone-number";

import { validatePassword } from "../../Utils/utils";
import { updateAccountDetails } from "../../Services/dashboardService";
import { allRoutes } from "../../Routes/AllRoutes";

interface AccountSettingsData extends UserState {
	_id?: string;
	changePassword?: string;
	addressObject?: PlaceType | null;
	currentStep?: string;
	status?: string;
	technicianId?: string;
	installerId?: string;
	electricity_bill?: string;
	electricity_usage?: string;
	supplyCharges?: string;
	deliveryCharges?: string;
	uploadedBillCost?: string;
	monthlyElectricityUsage?: { [key: string]: string };

	confirmPassword?: string;
	phone_no?: string;
	userId?: string;
	ImageUrl?: string;
	tokenKey?: any;
}

const defaultData: AccountSettingsData = {
	_id: "",
	name: "",
	email: "",
	addressObject: null,
	electricity_usage: "",
	phone: "",
	password: "",
	changePassword: "",
	technicianId: "",
	installerId: "",
	lastName: "",
};

const EditCustomer = ({ tokenFormData, token }: { tokenFormData?: AccountSettingsData; token?: string }) => {
	const { id } = useParams();
	const navigate = useNavigate();

	const [data, setData] = useState<AccountSettingsData>(defaultData);
	const [errors, setErrors] = useState<AccountSettingsData>(defaultData);
	const [loading, setLoading] = useState<boolean>(false);
	const [profilePicture, setProfilePicture] = useState<any>("");

	useEffect(() => {
		window.scrollTo(0, 0);

		if (tokenFormData) {
			console.log({ tokenFormData });
			setData({ ...tokenFormData, phone: tokenFormData.phone_no });
			setProfilePicture(tokenFormData.ImageUrl);
		}
	}, [tokenFormData]);

	const handleDropdown = (value: string, key: string) => {
		setData((state) => ({ ...state, [key]: value }));
	};

	const handleAddress = (value: PlaceType | null) => {
		setData((state) => ({ ...state, addressObject: value }));
		setErrors((state) => ({ ...state, address: "" }));
	};

	const handleSelectImage = (image: any) => {
		setProfilePicture(image);
	};

	const validateData = () => {
		console.log({ errors });
		const updatedErrors: any = { ...errors };

		updatedErrors.name = data.name ? "" : " First Name cannot be empty";
		updatedErrors.lastName = data.lastName ? "" : "Last Name cannot be empty";
		updatedErrors.email = data.email
			? !EmailValidator.validate(data.email)
				? "Enter a valid email"
				: ""
			: "Email cannot be empty";
		updatedErrors.address = data.addressObject?.description ? "" : "Address cannot be empty";
		updatedErrors.electricity_usage = data.electricity_usage
			? parseFloat(data.electricity_usage?.toString() || "") <= 0
				? "Electricity bill must be greater than 0"
				: ""
			: "Electricity bill cannot be empty";
		updatedErrors.password = data.password || data.confirmPassword ? validatePassword(data.password) : "";
		updatedErrors.confirmPassword =
			data.password || data.confirmPassword
				? data.confirmPassword !== data.password
					? "Password does not match"
					: !data.confirmPassword
					? "Confirm password cannot be empty"
					: ""
				: "";

		setErrors(updatedErrors);
		let foundError = Object.values(updatedErrors).find(Boolean);
		console.log(foundError);
		if (typeof foundError === "object") {
			foundError = Object.values(updatedErrors.monthlyElectricityUsage).find(Boolean);
		}
		return !foundError;
	};

	const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateData()) return;
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("ImageUrl", profilePicture ?? "");
			formData.append("name", data.name ?? "");
			formData.append("lastName", data.lastName ?? "");
			formData.append("email", data.email ?? "");
			formData.append("address", data.addressObject?.description ?? "");
			formData.append("electricity_usage", data.electricity_usage?.toString() ?? "");
			formData.append("phone_no", data.phone ?? "");
			formData.append("password", data.password ?? "");
			if (tokenFormData) {
				formData.append("token", token || "");
				await updateAccountDetails(tokenFormData.userId || "", formData);
			}

			toast.success("Customer information updated successfully!");
			navigate(allRoutes.LOGIN);
		} catch (error: any) {
			let specificError = error;
			if (specificError.includes("This email already exists.")) {
				specificError = "A user with this email already exists.";
				setErrors({ ...errors, email: specificError });
			} else {
				toast.error(specificError);
			}
		}
		setLoading(false);
	};

	const handleCancel = () => {
		navigate(-1);
	};
	const handleOnChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		isSubValue: boolean,
		parentKey?: keyof AccountSettingsData,
	) => {
		const { name, value } = e.target;

		setData((prevState) => ({
			...prevState,
			[name]: value,
		}));

		setErrors((prevState) => ({
			...prevState,
			[name]: "",
		}));
	};

	return (
		<>
			<Typography variant="h5">Add your details 😀</Typography>
			<Typography fontSize={15} mt={10}>
				Add your personal details to complete the registration process
			</Typography>
			<Divider sx={{ mt: 14, mb: 24 }} />

			<form onSubmit={handleUpdate}>
				<Box
					display="grid"
					gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
					gap={{ xs: 10, md: 32 }}
					alignItems="start"
				>
					<Box alignSelf="flex-start">
						<Typography variant="h5">Photo</Typography>
						<Typography fontSize={15} mt={10}>
							This will be displayed on customer's profile
						</Typography>
					</Box>
					<Box>
						<ImageUploader onUpdate={handleSelectImage} imageFile={profilePicture} />
					</Box>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						First Name
						<Asterisk />
					</Typography>
					<CustomTextField onChange={handleOnChange} value={data.name} name="name" placeholder="Name" />
					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Last Name
						<Asterisk />
					</Typography>
					<CustomTextField onChange={handleOnChange} value={data.lastName} name="lastName" placeholder="Last Name" />

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Email address
						<Asterisk />
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.email}
						error={errors.email}
						name="email"
						type="email"
						disabled={!!tokenFormData}
						placeholder="@example"
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Address
						<Asterisk />
					</Typography>
					<GoogleMapsTextField
						placeholder="Address"
						value={data.addressObject}
						onChange={handleAddress}
						error={errors.address}
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Electricity Bill
						<Asterisk />
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.electricity_usage}
						error={errors.electricity_usage?.toString()}
						name="electricity_usage"
						type="number"
						placeholder="2500"
						endIcon={<>/Mo</>}
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Phone Number
					</Typography>
					<MuiPhoneNumber
						defaultCountry={"us"}
						autoComplete="off"
						onChange={(phoneNumber: any) => setData({ ...data, phone: phoneNumber.toString() })}
						fullWidth
						variant="outlined"
						size="small"
						value={data.phone}
						required
						InputLabelProps={{ shrink: true }}
					/>

					{tokenFormData && (
						<>
							<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
								Password
								<Asterisk></Asterisk>
							</Typography>
							<CustomTextField
								onChange={handleOnChange}
								value={data.password}
								error={errors.password}
								name="password"
								type="password"
								placeholder="********"
								autoComplete="new-password"
							/>

							<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
								Confirm Password
								<Asterisk></Asterisk>
							</Typography>
							<CustomTextField
								onChange={handleOnChange}
								value={data.confirmPassword}
								error={errors.confirmPassword}
								name="confirmPassword"
								type="password"
								placeholder="********"
								autoComplete="new-password"
							/>
						</>
					)}
				</Box>

				<Box display="flex" alignItems="center" justifyContent="flex-end" gap={20}>
					<CustomButton disabled={loading} variant="outlined" color="secondary" onClick={handleCancel}>
						Cancel
					</CustomButton>
					<CustomButton disabled={loading} type="submit">
						Save Changes
					</CustomButton>
				</Box>
			</form>
		</>
	);
};

export default EditCustomer;
