import { createTheme } from "@mui/material";
import { borderRadius } from "./Utils/spacings";
import { ColorsInterface } from "./Redux/Slices/generalSlice";

const createAppTheme = (colors: ColorsInterface) => {
	return createTheme({
		palette: {
			primary: {
				main: colors.primary,
				light: colors.primaryLight,
			},
			secondary: {
				main: colors.border,
			},
			text: {
				primary: colors.text,
				secondary: colors.gray,
			},
			success: {
				main: colors.success,
				light: colors.successBg,
				dark: colors.successLight,
			},
			error: {
				main: colors.error,
				light: colors.errorBg,
				dark: colors.errorLight,
			},
		},

		typography: {
			fontFamily: ["Inter"].join(","),
			allVariants: {
				color: colors.text,
				fontSize: "14px",
				lineHeight: 1.3,
			},
			h1: {
				fontSize: "56px",
				fontWeight: 600,
			},
			h2: {
				fontSize: "32px",
				fontWeight: 600,
			},
			h3: {
				fontSize: "28px",
				fontWeight: 600,
			},
			h4: {
				fontSize: "24px",
				fontWeight: 600,
			},
			h5: {
				fontSize: "20px",
				fontWeight: 600,
			},
			h6: {
				fontSize: "16px",
				fontWeight: 500,
			},
			body2: {
				fontSize: "14px",
				fontWeight: 500,
			},
		},

		breakpoints: {
			values: {
				xs: 0,
				sm: 768,
				md: 1024,
				lg: 1440,
				xl: 1920,
			},
		},

		spacing: 1,
		shape: {
			borderRadius: 1,
		},

		components: {
			MuiTypography: {
				styleOverrides: {
					root: {
						"& .link, &.link": {
							color: colors.link,
							cursor: "pointer",
						},
					},
				},
			},

			MuiInputBase: {
				styleOverrides: {
					root: {
						"& textarea, input": {
							padding: 0,
							paddingBlock: 10,
							color: colors.text,
							fontSize: "14px",
							lineHeight: "20px",
							"&:-internal-autofill-selected": {
								boxShadow: "0 0 0 50px white inset",
							},
						},
						fieldset: {
							borderColor: colors.border,
						},

						"&.MuiInputBase-root": {
							backgroundColor: "white",
						},
						"&.MuiInput-underline input": {
							paddingBlock: 10,
						},
						"&.MuiInput-underline:after": {
							borderBottomColor: colors.border,
						},
						"&.MuiOutlinedInput-root": {
							padding: `${0}px 16px`,
							borderRadius: borderRadius.xs,

							"&.MuiAutocomplete-inputRoot": {
								padding: `10px 16px`,
								"& .MuiAutocomplete-input": {
									padding: 0,
									"&:-internal-autofill-selected": {
										boxShadow: "0 0 0 50px white inset",
									},
								},
							},

							"& .MuiSelect-outlined": {
								padding: 0,
								paddingBlock: "10px",
								paddingRight: 32,
							},

							"&.Mui-focused fieldset, &:hover fieldset": {
								borderColor: colors.primary,
								borderWidth: 1,
							},
						},

						"& input::placeholder, & textarea::placeholder": {
							color: "gray",
							opacity: 0.7,
							textAlign: "left",
						},
					},
				},
			},

			MuiButton: {
				styleOverrides: {
					root: {
						paddingBlock: 15,
						paddingInline: 32,
						fontSize: 16,
						fontWeight: 500,
						textTransform: "unset",
						boxShadow: "unset",
						borderRadius: borderRadius.xs,

						"&:hover, &:focus, &:active": {
							boxShadow: "none",
						},

						"&.MuiButton-containedPrimary": {
							"&:disabled": {
								color: "white",
								backgroundColor: colors.primaryLight,
							},
						},

						"&.MuiButton-outlined": {
							borderColor: colors.border,
							color: colors.text,

							"&:disabled": {
								color: "white",
								backgroundColor: colors.border,
							},
							"&.MuiButton-outlinedPrimary": {
								borderColor: colors.primary,
								color: colors.primary,
								"&:disabled": {
									color: colors.primary + 40,
									backgroundColor: colors.primary + 20,
									borderColor: colors.primary + 40,
								},
							},
						},
					},
				},
			},

			MuiDivider: {
				styleOverrides: {
					root: {
						borderColor: colors.border,
					},
				},
			},

			MuiBackdrop: {
				styleOverrides: {
					root: {
						WebkitBackdropFilter: "blur(2px)",
						backdropFilter: "blur(2px)",
					},
				},
			},

			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px",
						"&.MuiMenu-paper": {
							borderRadius: borderRadius.xs,
						},
					},
				},
			},

			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						backgroundColor: "white",
						borderRadius: 4,
						color: colors.text,
						boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
						textAlign: "center",
					},
					arrow: {
						color: "white",
						textShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
					},
				},
			},
		},
	});
};

export default createAppTheme;
