import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/meter-replacement";

// =====|  Meter Replacement Service  |=====

const MeterReplacementService = {
	getMeterReplacementStatus: () => http.get(`${apiEndpoint}`, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const getMeterReplacementStatus = () => {
	return MeterReplacementService.getMeterReplacementStatus();
};
