import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/interconnection";

// =====|  Interconnection Service  |=====

const InterconnectionService = {
	getInterconnectionStatus: () => http.get(`${apiEndpoint}`, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const getInterconnectionStatus = () => {
	return InterconnectionService.getInterconnectionStatus();
};
