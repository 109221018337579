import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/colors";

// =====|  Dashboard Service  |=====

const ColorsService = {
	getColors: () => http.get(`${apiEndpoint}/`),
};

// =====|  APIs  |=====

export const getColors = () => {
	return ColorsService.getColors();
};
