import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomStepper from "../../../Common/CustomStepper";
import {
	Description,
	EditCalendarOutlined,
	EventAvailableOutlined,
	HourglassEmptyOutlined,
	UploadFileOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../../../Routes/AllRoutes";
import InformationBlock from "../../InformationBlock";
import { navbarHeight, tabsHeight } from "../../../../Utils/spacings";
import { useSelector } from "../../../../Redux/reduxHooks";
import { selectAllTextSnippets } from "../../../../Redux/Slices/textSnippetsSlice";
import { selectColors } from "../../../../Redux/Slices/generalSlice";

export const steps = {
	PENDING: { text: "Pending", icon: <HourglassEmptyOutlined />, status: 0 },
	SUBMITTED: { text: "Submitted", icon: <UploadFileOutlined />, status: 1 },
	IN_REVIEW: { text: "In review", icon: <EditCalendarOutlined />, status: 2 },
	ACCEPTED: { text: "Accepted", icon: <EventAvailableOutlined />, status: 3 },
};

const PermitApplication = ({
	statusFromAPI,
	moveToUtilityTab,
}: {
	statusFromAPI?: string;
	moveToUtilityTab: () => void;
}) => {
	const navigate = useNavigate();
	const colors = useSelector(selectColors)

	const [status, setStatus] = useState<null | any>(steps.PENDING.status);

	const textSnippets = useSelector(selectAllTextSnippets);

	useEffect(() => {
		const actualStatus =
			Object.values(steps).find((step) => step.text === statusFromAPI)?.status || steps.PENDING.status;
		setStatus(actualStatus);
	}, [statusFromAPI]);

	return (
		<>
			<InformationBlock
				icon={{
					iconComponent: Description,
					iconBg: colors.primary + "30",
					iconColor: colors.primary,
					iconSize: 75,
				}}
				title={
					<>
						{textSnippets["permit_application_heading"] || "Your permit application "}
						{status === steps.SUBMITTED.status ? " has been " : " is "}{" "}
						<Typography
							variant="inherit"
							component="span"
							color={status === steps.ACCEPTED.status ? "success.dark" : "primary.main"}
						>
							{status === steps.PENDING.status
								? "pending"
								: status === steps.SUBMITTED.status
								? "submitted"
								: status === steps.IN_REVIEW.status
								? "in review"
								: "accepted"}
						</Typography>
					</>
				}
				subtitle={
					textSnippets["permit_application_notification_text"] ||
					"You will be notified as soon as the design has completed. Please check again later."
				}
				buttons={[
					{
						buttonText:
							status === steps.ACCEPTED.status
								? textSnippets["permit_application_continue_button"] || "Continue"
								: textSnippets["permit_application_home_button"] || "Back To Homepage",
						buttonOnClick: () =>
							status === steps.ACCEPTED.status ? moveToUtilityTab?.() : navigate(allRoutes.PROPOSAL_ACCEPTANCE),
					},
				]}
				height={`calc(100vh - ${navbarHeight}px - ${tabsHeight}px  - 185px - 105px)`} // here (185px = height of CustomStepper) and (105px = height of CustomToggleButton)
			/>

			<Box pt={42} maxWidth={640} mx="auto" mb={{ xs: 32, sm: 70 }}>
				<CustomStepper steps={Object.values(steps)} activeStep={status} />
			</Box>
		</>
	);
};
export default PermitApplication;
