import { Box, Typography } from "@mui/material";
import CustomButton from "../../Common/CustomButton";

const ViewDesign = ({ image }: { image?: string }) => {
	const handleDownload = () => {
		const link = document.createElement("a");
		
		link.download = "CAD-Design";
		link.href = image || "";
		link.click();
	};

	return (
		<>
			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", py: 48 }}>
				<Box>
					<Typography variant="h4">CAD Design</Typography>
					<Typography fontSize={16} mt={8}>
						Lorem ipsum dolor
					</Typography>
				</Box>
				<CustomButton onClick={handleDownload}>Download PDF</CustomButton>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					paddingBottom: "70px",
					"& img": {
						maxWidth: { xs: "100%", sm: 1000 },
						outline: "4px solid black",
					},
				}}
			>
				<img src={image || "https://i.pinimg.com/originals/b0/f6/dd/b0f6dddc85d03df3c42ccd4ce4d3c65a.png"} alt="" />
			</Box>
		</>
	);
};

export default ViewDesign;
