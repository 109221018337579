import axios from "axios";

// =====|  APIs  |=====

export const convertPDFtoJPG = async (pdfFile: File) => {
	const secret = process.env.REACT_APP_CONVERT_API_SECRET || "";
	const apiKey = process.env.REACT_APP_CONVERT_API_KEY || "";

	const apiUrl = `https://v2.convertapi.com/convert/pdf/to/jpg?Secret=${secret}`;

	const requestData = new FormData();
	requestData.append("ApiKey", apiKey);
	requestData.append("File", pdfFile);

	try {
		const response = await axios.post(apiUrl, requestData);
		if (response.status === 200) {
			const jpgFiles = response.data.Files.map((file: any) => {
				const blob = base64ToBlob(file.FileData, `image/${file.FileExt}`);
				return new File([blob], file.FileName, { type: blob.type });
			});

			return jpgFiles;
		}
	} catch (error) {
		console.error("Error converting PDF to JPG:", error);
	}
};

// =====|  Helper methods  |=====

const base64ToBlob = (base64: string, type: string) => {
	const byteCharacters = atob(base64);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	return new Blob([byteArray], { type });
};

export const extractValueFromText = (text: string, regex: RegExp) => {
	const match = text.match(regex);
	return match ? parseFloat(match[1]) : 0;
};

export const getEversourceMonthlyBills = (text = "") => {
	const lines = text.split("\n"); // splitting text into lines
	// filtering out only those lines, which contains month name
	const monthLines = lines.filter((line, index) => {
		const nextLine = lines[index + 1];
		return /\b(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\b/i.test(line) && /\d/.test(nextLine.split(" ")[0]);
	});

	const valuesWithMonths: Array<{ month: string; value: number | string }> = [];
	monthLines.forEach((line, idx) => {
		const lineIndex = lines.findIndex((item) => item === line);

		if (lineIndex < lines.length - 1) {
			const numOfMonths = idx ? 6 : 8;
			const months = line.split(" ", numOfMonths);
			const values = lines[lineIndex + 1].split(" ", numOfMonths);

			months.forEach((month, monthIndex) => {
				const value = parseFloat(values[monthIndex]);

				if (
					!isNaN(value) &&
					["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].includes(month) &&
					!valuesWithMonths.find((item) => item.month === month)
				) {
					valuesWithMonths.push({ month, value });
				}
			});
		}
	});

	return valuesWithMonths;
};

export const getNationalGridMonthlyBills = (text = "") => {
	const monthLines = text
		.split("\n")
		.filter((line) => /^(\s*)\b(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\b/i.test(line));

	const valuesWithMonths: Array<{ month: string; value: number | string }> = [];

	for (const line of monthLines) {
		const parts = line.split(/ (?=(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s+\d{1,2})/i);
		for (let i = 0; i < parts.length; i += 2) {
			const words = parts[i].split(" ", 3);
			const month = words[0];
			const value = parseFloat(words[2]);

			if (!valuesWithMonths.find((item) => item.month === month) && !isNaN(value)) {
				valuesWithMonths.push({ month, value });
			}

			if (i + 1 < parts.length) {
				const monthWithValue = parts[i + 1].split(" ", 3);
				const month = monthWithValue[0];
				const value = parseFloat(monthWithValue[2]);

				if (!valuesWithMonths.find((item) => item.month === month) && !isNaN(value)) {
					valuesWithMonths.push({ month, value });
				}
			}
		}
	}

	return valuesWithMonths;
};
