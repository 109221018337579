import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

// Define the type for the video client state
export interface VideoClientState {
  apiKey: string;
  user: {
    id: string;
    name: string;
    image: string;
    // type?: "authenticated" 
  };
  token: string;
}

// Define the initial state
const initialState: VideoClientState = {
  apiKey: "",
  user: {
    id: "",
    name: "",
    image: "",
  },
  token: "",
};

// Create the slice
export const videoSlice = createSlice({
  name: "video",
  initialState: initialState,
  reducers: {
    // Action to save video client state
    saveVideoClientState: (state, action: PayloadAction<VideoClientState>) => {
      state.apiKey = action.payload.apiKey;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    // Action to reset video client state
    resetVideoClientState: () => initialState,
  },
});

// Export actions
export const { saveVideoClientState, resetVideoClientState } = videoSlice.actions;

// Selectors
export const selectVideoClientState = (state: RootState) => state.video;

// Export the reducer
export default videoSlice.reducer;





