import { Box, Drawer, List, ListItem, styled } from "@mui/material";
import { sidebarWidths } from "../../Utils/spacings";

interface DrawerListItemProps {
	isActive?: boolean;
}

export const StyledDrawerBox = styled(Box)(() => ({
	minHeight: "100%",
	height: "max-content",
	paddingTop: "10px",
	overflowY: "auto",
	WebkitBackdropFilter: "saturate(180%) blur(20px)",
	backdropFilter: "saturate(180%) blur(20px)",
	backgroundColor: "rgba(255, 255, 255, 0.7)",
	"&::-webkit-scrollbar": { display: "none" },
}));

export const StyledDrawerList = styled(List)(() => ({
	// marginTop: "10px",
	"& .MuiListItemIcon-root": {
		minWidth: "unset",
		marginRight: "14px",
		svg: {
			width: "18px",
			height: "18px",
		},
	},
}));

export const StyledDrawer = styled(Drawer)(() => ({
	"& .MuiDrawer-paper": {
		// backgroundColor: "transparent",
		width: sidebarWidths.xs,
		["@media (min-width: 1024px)"]: {
			width: sidebarWidths.md,
		},
		["@media (min-width: 1440px)"]: {
			width: sidebarWidths.lg,
		},
		boxShadow: "none",
	},
}));
