import http from "./httpService";


const apiEndpoint = "/chat-config-msg";

// =====|  Dashboard Service  |=====

const DefaultMsgService = {
	getDefaultMsg: () => http.get(`${apiEndpoint}/`),
};

// =====|  APIs  |=====

export const getDefaultMsg = () => {
	return DefaultMsgService.getDefaultMsg();
};

