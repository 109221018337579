import { ReactNode } from "react";
import Loader from "../Common/Loader";

const SchedulingPageLayout = ({ children, loading }: { loading?: boolean; children?: ReactNode }) => {
	return (
		<>
			<Loader open={loading} />
			{children}
		</>
	);
};

export default SchedulingPageLayout;
