import { Typography } from "@mui/material";
import CustomDialog from "../Common/CustomDialog";
import { CheckOutlined } from "@mui/icons-material";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import CustomButton from "../Common/CustomButton";
import { IconSquareBox } from "../Login/loginStyles";

interface GoogleConfirmationDialogProps {
	open: boolean;
	onClose?: () => void;
}

const GoogleConfirmationDialog = ({ open, onClose }: GoogleConfirmationDialogProps) => {
	const colors = useSelector(selectColors);

	return (
		<CustomDialog open={open} onClose={onClose}>
			<IconSquareBox
				sx={{
					border: `1px solid ${colors.border}`,
				}}
			>
				<CheckOutlined />
			</IconSquareBox>

			<Typography variant="h2" my={16} textAlign="center">
				User account created
			</Typography>
			<Typography fontSize={16} textAlign="center" mb={32} color={colors.textMid}>
				Please double-check the user information in your account settings to make sure that it is up-to-date. This
				information is essential for your contract!
			</Typography>

			<CustomButton fullWidth onClick={onClose} sx={{ mb: 10 }}>
				Close
			</CustomButton>
		</CustomDialog>
	);
};

export default GoogleConfirmationDialog;
