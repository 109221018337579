import SchedulingPageLayout from "../SchedulingPageLayout";
import InformationBlock from "../InformationBlock";
import { useSelector } from "../../../Redux/reduxHooks";
import { selectAllTextSnippets } from "../../../Redux/Slices/textSnippetsSlice";

const ProposalAcceptance = ({ onContinue }: { onContinue?: () => void }) => {
	const textSnippets = useSelector(selectAllTextSnippets);

	return (
		<SchedulingPageLayout>
			<InformationBlock
				title={textSnippets['proposal_accept_heading'] || "Thank you for accepting the proposal"}
				subtitle={
					<>
						{textSnippets['arrange_survey_text'] || "To arrange a site survey, please proceed by clicking on continue"}
					</>
				}
				buttons={[
					{
						buttonText:textSnippets['continue_button'] || "Continue",
						buttonOnClick: () => onContinue?.(),
						// buttonOnClick: () => navigate(allRoutes.SITE_SURVEY),
					},
				]}
			/>
		</SchedulingPageLayout>
	);
};

export default ProposalAcceptance;
