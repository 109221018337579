import http from "./httpService";
import { getAuthHeader } from "./userService";

// =====|  User Service  |=====

const UserService = {
	storeFirebaseToken: (token: string) =>
		http.post(`/save-fcm-token`, { fcmToken: token }, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const storeFirebaseToken = async (token: string): Promise<any | void> => {
	return UserService.storeFirebaseToken(token);
};
