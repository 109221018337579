import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/site-survey";

// =====|  Profile Service  |=====

const ProfileService = {
	getAppointmentDetails: () => http.get(`${apiEndpoint}`, { headers: getAuthHeader() }),
	assignTechnician: () => http.post(`${apiEndpoint}/assign-site-technician`, {}, { headers: getAuthHeader() }),
	getAvailableSlotsOfTechnician: (date: string) =>
		http.get(`${apiEndpoint}/available-slots/${date}`, { headers: getAuthHeader() }),
	bookSiteSurvey: (data: any) => http.post(`${apiEndpoint}/book-site-survey`, data, { headers: getAuthHeader() }),
	updatePaymentStatus: (id: string) =>
		http.put(`${apiEndpoint}/update-payment-status/${id}`, {}, { headers: getAuthHeader() }),

	// updateSiteSurvey: (id: string, data: any) =>
	// 	http.put(`${apiEndpoint}/edit/${id}`, data, { headers: getAuthHeader() }),
	// updateSurveyStatus: (id: string, data: any) =>
	// 	http.post(`${apiEndpoint}/status/${id}`, data, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const getAppointmentDetails = () => {
	return ProfileService.getAppointmentDetails();
};

export const assignTechnician = () => {
	return ProfileService.assignTechnician();
};

export const getAvailableSlotsOfTechnician = (date: string) => {
	return ProfileService.getAvailableSlotsOfTechnician(date);
};

export const bookSiteSurvey = (date: string, time: string) => {
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const data = { date, time, timeZone };
	return ProfileService.bookSiteSurvey(data);
};

export const updatePaymentStatus = (id: string) => {
	return ProfileService.updatePaymentStatus(id);
};

// export const updateSiteSurvey = (id: string, date: string, time: string) => {
// 	const data = { date, time };
// 	return ProfileService.updateSiteSurvey(id, data);
// };

// export const updateSurveyStatus = (id: string, status: string) => {
// 	const data = { status };
// 	return ProfileService.updateSurveyStatus(id, data);
// };
