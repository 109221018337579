import jwtDecode from "jwt-decode";
import { UserState, saveUserState } from "../Redux/Slices/userSlice";
import { AppDispatch } from "../Redux/store";
import { accessTokenKey, googleLoginKey, googleLoginValue, roles } from "../Utils/tokenKeyValue";
import http from "./httpService";
import Cookies from "js-cookie";

const apiEndpoint = "/auth";

// =====|  User Service  |=====

const UserService = {
	login: (userData: any) => http.post(`${apiEndpoint}/login`, userData),
	signUp: (userData: any) => http.post(`${apiEndpoint}/signup`, userData),
	verifyEmail: (userId: string, token: string) => http.put(`${apiEndpoint}/verify-email/${userId}/${token}`),
	resendVerifyEmail: (userId: string) => http.post(`${apiEndpoint}/resend-verification/${userId}`),
	decodeReferralLink: (token: string) => http.get(`${apiEndpoint}/decode-referral-link/${token}`),
	verifyTokenService: (token: string) =>
		http.get(`${apiEndpoint}/decode-referral-link/${token}`, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const loginUser =
	(data: { email: string; password: string; timeZone?: string }) => async (): Promise<any | void> => {
		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		data.timeZone = timeZone;

		const { data: user }: any = await UserService.login(data);
		console.log("User: ", user);

		if (user?.access_token !== "An Email sent to your account please verify") setJwtToken(user?.access_token);

		return user;
	};

export const signUpUser =
	(userData: UserState, isSocialLogin?: boolean) =>
	async (dispatch: AppDispatch): Promise<any> => {
		try {
			const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

			const data = {
				name: userData.name,
				lastName: userData.lastName,
				email: userData.email?.toLowerCase(),
				password: userData.password,
				address: userData.address,
				phone_no: userData.phone,
				electricity_usage: userData.bill,
				referralLink: userData.referralLink ?? "",
				postalCode: userData.postalCode,
				timeZone,
				...(isSocialLogin
					? {
							googleId: userData.id,
							isGoogleSignUp: true,
					  }
					: {}),
			};

			const { data: user } = await UserService.signUp(data);

			const userObject = user?.user?.user || user?.message?.user;

			if (userObject?._id) {
				// commenting this code for testing now
				if (isSocialLogin) {
					// we're only setting token for google users, because other users have to verify their emails first
					setJwtToken(user?.access_token);
				}

				if (userObject) {
					const userForRedux = {
						id: userObject?._id,
						bill: userObject?.power_usage?.toString(),
						address: userObject?.address,
						name: userObject?.name,
						lastName: userObject?.lastName,
						email: userObject?.email,
						role: userObject?.role,
						picture: userObject?.imageUrl || userData.picture || "",
						postalCode: userObject?.postalCode || "",
					};
					dispatch(saveUserState(userForRedux));
				}
			}

			return user;
		} catch (error) {
			console.error("signUpUser (API): ", error);
			throw error;
		}
	};

export const verifyEmail = async (userId: string, token: string): Promise<any | void> => {
	return UserService.verifyEmail(userId, token);
};

export const resendVerifyEmail = async (userId: string): Promise<any | void> => {
	return UserService.resendVerifyEmail(userId);
};

export const decodeReferralLink = async (token: string) => {
	return UserService.decodeReferralLink(token);
};

export const setJwtToken = (token: string, expiryTime?: number) => {
	const decodedToken: any = jwtDecode(token);
	const timestampInSeconds = decodedToken.exp;
	const timestampInMilliseconds = timestampInSeconds * 1000;
	const currentTimestamp = Date.now();
	const timeDifference = timestampInMilliseconds - currentTimestamp;
	const daysDifference = timeDifference / (24 * 60 * 60 * 1000);
	const expiryDays = daysDifference.toFixed(0) ?? 1;
	Cookies.set(accessTokenKey, token, { expires: expiryTime ?? parseInt(expiryDays) });
};

export const getJwtToken = (): string => {
	return Cookies.get(accessTokenKey) ?? "";
};

export const getAuthHeader = () => {
	return { Authorization: `Bearer ${getJwtToken()}` };
};

export const isUserLoggedIn = (): boolean => {
	return !!Cookies.get(accessTokenKey);
};

export const isGoogleLoggedIn = (): boolean => {
	try {
		return localStorage.getItem(googleLoginKey) === googleLoginValue;
	} catch (ex) {
		return false;
	}
};

export const setGoogleLoggedIn = () => {
	localStorage.setItem(googleLoginKey, googleLoginValue);
};

export const logoutUser = () => {
	Cookies.remove(accessTokenKey);
	localStorage.removeItem(googleLoginKey);
};

const getLoggedInUser = (): null | { role: string } => {
	if (!isUserLoggedIn()) return null;

	try {
		const loggedInUser: any = jwtDecode(Cookies.get(accessTokenKey) || "");
		return loggedInUser;
	} catch (ex) {
		return null;
	}
};

export const isAccessedByInstaller = (): boolean => {
	const loggedInUser: any = getLoggedInUser();
	return (
		[roles.INSTALLER_ADMIN, roles.OFFICE_MANAGER, roles.MANAGER, roles.REPRESENTATIVE].includes(
			loggedInUser?.accessed_by?.role,
		) || false
	);
};

isAccessedByInstaller();
export const verifyTokenService = (data: any) => {
	return UserService.verifyTokenService(data);
};
