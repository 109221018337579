import { ReactNode } from "react";
import { BigIconCircle } from "./schedulingStyles";
import { navbarHeight, tabsHeight } from "../../Utils/spacings";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { DoneOutlineRounded } from "@mui/icons-material";
import CustomButton from "../Common/CustomButton";

interface Icon {
	iconComponent?: any;
	iconBg?: string;
	iconColor?: string;
	iconSize?: number | string;
	iconBorderColor?: string;
}

interface Button {
	buttonText: string;
	variant?: any;
	buttonOnClick: () => void;
}

interface InformationBlockProps {
	title?: string | ReactNode;
	subtitle?: string | ReactNode;
	buttons?: Button[];
	height?: string | number;
	icon?: Icon;
}

const InformationBlock = ({ title, subtitle, buttons, height, icon }: InformationBlockProps) => {
	const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
				minHeight: height || `calc(100vh - ${navbarHeight}px - ${tabsHeight}px)`,
				textAlign: "center",
				paddingBlock: 32,
			}}
		>
			<BigIconCircle
				sx={{
					backgroundColor: icon?.iconBg || "primary.main",
					fontSize: icon?.iconSize || 50,

					...(icon?.iconBorderColor
						? {
								outline: `6px solid ${icon.iconBorderColor}`,
								width: 81,
								height: 81,
								my: 6,
						  }
						: {}),
				}}
			>
				{icon?.iconComponent ? (
					<icon.iconComponent style={{ color: icon?.iconColor || "white", fontSize: "inherit" }} />
				) : (
					<DoneOutlineRounded fontSize="inherit" sx={{ color: icon?.iconColor || "white" }} />
				)}
			</BigIconCircle>

			<Typography variant={isMobileView ? "h3" : "h2"} mt={24} mb={24}>
				{title}
			</Typography>
			{!!subtitle && (
				<Typography fontSize={18} mb={32} maxWidth={390}>
					{subtitle}
				</Typography>
			)}
			{buttons?.map((button, index) => (
				<CustomButton
					key={index}
					variant={button.variant || "contained"}
					sx={{ minWidth: 300, marginBlock: 10 }}
					onClick={button.buttonOnClick}
				>
					{button.buttonText}
				</CustomButton>
			))}
		</Box>
	);
};

export default InformationBlock;
