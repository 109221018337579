import { Backdrop, CircularProgress, Typography } from "@mui/material";

interface LoaderProps {
	open?: boolean;
	handleClose?: any;
	text?: string;
}

const Loader = ({ open = false, handleClose, text }: LoaderProps) => {
	return (
		<Backdrop
			sx={{
				color: "#fff",
				zIndex: (theme) => theme.zIndex.drawer + 10,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				gap: 14,
			}}
			open={open}
			onClick={handleClose}
		>
			<CircularProgress color="inherit" />
			{!!text && <Typography color="inherit">{text}</Typography>}
		</Backdrop>
	);
};

export default Loader;
