import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/cad-design";

// =====|  CAD Design Service  |=====

const CADDesignService = {
	getCADDesignStatus: () => http.get(`${apiEndpoint}`, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const getCADDesignStatus = () => {
	return CADDesignService.getCADDesignStatus();
};
