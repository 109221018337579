import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import SchedulingPageLayout from "../../SchedulingPageLayout";
import CustomToggleButton from "../../../Common/CustomToggleButton";
import TownInspection from "./TownInspection";
import MeterReplacement from "./MeterReplacement";
import { toast } from "react-toastify";
import { getTowInspectionStatus } from "../../../../Services/townInspectionService";
import { getMeterReplacementStatus } from "../../../../Services/meterReplacementService";

const inspectionOptions = {
	INSPECTION: { text: "Town Inspection", value: 1 },
	METER: { text: "Meter Replacement", value: 2 },
};

const TownInspectionMeterReplacement = ({ onContinue }: { onContinue?: () => void }) => {
	const [option, setOption] = useState<number | string>(inspectionOptions.INSPECTION.value);
	const [loading, setLoading] = useState<boolean>(false);
	const [town, setTown] = useState<string>("");
	const [meter, setMeter] = useState<string>("");

	useEffect(() => {
		getTownData();
		getMeterData();
	}, []);

	const getTownData = async () => {
		setLoading(true);
		try {
			const { data: town } = await getTowInspectionStatus();
			setTown(town?.status || "");
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const getMeterData = async () => {
		setLoading(true);
		try {
			const { data: meter } = await getMeterReplacementStatus();
			setMeter(meter?.status || "");

			// if meter replacement is present, then open meter replacement tab by default
			if (meter?.status) {
				setOption(inspectionOptions.METER.value);
			}
		} catch (error: any) {
			if (error === "No meter replacement request found!") {
				setMeter("");
			} else {
				toast.error(error);
			}
		}
		setLoading(false);
	};

	const isInspectionSelected = option === inspectionOptions.INSPECTION.value;

	return (
		<SchedulingPageLayout loading={loading}>
			<Box display="flex" justifyContent="center" mt={50}>
				<CustomToggleButton
					isWhiteVariant
					value={option}
					onChange={(val) => setOption(val)}
					options={Object.values(inspectionOptions)}
				/>
			</Box>

			{isInspectionSelected ? (
				<TownInspection moveToMeterReplacement={() => setOption(inspectionOptions.METER.value)} statusFromAPI={town} />
			) : (
				<MeterReplacement statusFromAPI={meter} onContinue={onContinue} />
			)}
		</SchedulingPageLayout>
	);
};
export default TownInspectionMeterReplacement;
