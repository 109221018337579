import { Navigate, Route, Routes } from "react-router-dom";
import SignUp from "../Components/SignUp/SignUp";
import Login from "../Components/Login/Login";
import Home from "../Components/Home/Home";
import { PrivateRoute } from "./PrivateRoutes";
import { PublicRoute } from "./PublicRoutes";
import Estimate from "../Components/Estimate/Estimate";
import WhyUs from "../Components/WhyUs/WhyUs";
import SolarReport from "../Components/SolarReport/SolarReport";
import ResetPassword from "../Components/ResetPassword/ResetPassword";
import VerifyEmail from "../Components/VerifyEmail/VerifyEmail";
import AccountSettings from "../Components/AccountSettings/AccountSettings";
import { useEffect, useState } from "react";
import { isAccessedByInstaller, isUserLoggedIn } from "../Services/userService";
import Loader from "../Components/Common/Loader";
import { toast } from "react-toastify";
import { getAssignedRepresentative, getProfile } from "../Services/profileService";
import { useDispatch, useSelector } from "../Redux/reduxHooks";
import { allRoutes } from "./AllRoutes";
import Scheduling from "../Components/Scheduling/Scheduling";
import { requestNotificationPermission } from "../firebase";
import NotFound from "../Components/NotFound/NotFound";
import { UserState, selectUser } from "../Redux/Slices/userSlice";
import AccountCreation from "../Components/Common/AccountCreation";
import UserVideoCall from "../Components/ChatWidget/UserVideoCall";

interface RouteWithComponent {
	path: string;
	Component: React.FC;
	isPrivate?: boolean;
	accessTo?: {
		installerAccess?: boolean;
	};
}

const routesWithComponents = {
	HOME: { path: allRoutes.HOME, Component: Home },
	ESTIMATE: { path: allRoutes.ESTIMATE, Component: Estimate },
	USER_VIDEO_CALL: { path: allRoutes.USER_VIDEO_CALL, Component: UserVideoCall },
	SOLAR_REPORT: { path: allRoutes.SOLAR_REPORT, Component: SolarReport },
	LOGIN: { path: allRoutes.LOGIN, Component: Login },
	SIGN_UP: { path: allRoutes.SIGN_UP, Component: SignUp },
	RESET_PASSWORD: {
		path: allRoutes.RESET_PASSWORD,
		Component: ResetPassword,
		accessTo: {
			installerAccess: false,
		},
	},
	VERIFY_EMAIL: {
		path: allRoutes.VERIFY_EMAIL,
		Component: VerifyEmail,
		accessTo: {
			installerAccess: false,
		},
	},
	WHY_CHOOSE_US: { path: allRoutes.WHY_CHOOSE_US, Component: WhyUs, isPrivate: true },
	SCHEDULING: {
		path: allRoutes.SCHEDULING,
		Component: Scheduling,
		isPrivate: true,
		accessTo: {
			installerAccess: false,
		},
	},
	// PAYMENT: { path: allRoutes.PAYMENT, Component: Payment, isPrivate: true },
	ACCOUNT_SETTINGS: {
		path: allRoutes.ACCOUNT_SETTINGS,
		Component: AccountSettings,
		isPrivate: true,
		accessTo: {
			installerAccess: false,
		},
	},
	ACCOUNT_CREATION: {
		path: allRoutes.ACCOUNT_CREATION,
		Component: AccountCreation,
	},
	NOT_FOUND: { path: "*", Component: NotFound, isPrivate: true },
};

const RouteNavigation = () => {
	const dispatch = useDispatch();
	const user: UserState = useSelector(selectUser);

	const isInstallerAccess = isAccessedByInstaller();

	const [loading, setLoading] = useState<boolean>(false);
	const [renderingRoutes, setRenderingRoutes] = useState<Array<RouteWithComponent>>([]);

	useEffect(() => {
		fetchUserProfile();
	}, []);

	useEffect(() => {
		const allRoutesFiltered: RouteWithComponent[] = isInstallerAccess
			? Object.values(routesWithComponents).filter(
				(item: RouteWithComponent) =>
					(item.accessTo ? isInstallerAccess && item.accessTo.installerAccess : item)
			)
			: Object.values(routesWithComponents);

		console.log(allRoutesFiltered);
		console.log(isAccessedByInstaller());
		setRenderingRoutes(allRoutesFiltered);
	}, [user.id, isAccessedByInstaller(), user.accessedBy]);

	const fetchUserProfile = async () => {
		if (isUserLoggedIn()) {
			setLoading(true);
			try {
				await requestNotificationPermission();
				await dispatch(getProfile());
				await dispatch(getAssignedRepresentative());
			} catch (error: any) {
				toast.error(error);
			}
			setLoading(false);
		}
	};

	return (
		<>
			<Loader open={loading} />
			<Routes>
				{renderingRoutes.map((item: RouteWithComponent) => {
					if (item.path === allRoutes.USER_VIDEO_CALL) {
						return (
							<Route
								key={item.path}
								path={item.path}
								element={<item.Component />}
							/>
						);
					}

					const ComponentWrapper = item.isPrivate ? PrivateRoute : PublicRoute;

					return (
						<Route
							key={item.path}
							path={item.path}
							element={
								item.path === allRoutes.HOME ? (
									<Navigate to={allRoutes.ESTIMATE} replace={true} />
								) : (
									<ComponentWrapper>
										<item.Component />
									</ComponentWrapper>
								)
							}
						/>
					);
				})}
			</Routes>
		</>
	);
};

export default RouteNavigation;
