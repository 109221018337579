import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import SchedulingPageLayout from "../../SchedulingPageLayout";
import CustomToggleButton from "../../../Common/CustomToggleButton";
import PermitApplication from "./PermitApplication";
import UtilityInterconnection from "./UtilityInterconnection";
import { getPermitStatus } from "../../../../Services/permitService";
import { getInterconnectionStatus } from "../../../../Services/interconnectionService";
import { toast } from "react-toastify";

const permitOptions = {
	PERMIT: { text: "Permit Application", value: 1 },
	UTILITY: { text: "Utility Interconnection", value: 2 },
};

const PermitInterconnection = ({ onContinue }: { onContinue?: () => void }) => {
	const [tab, setTab] = useState<number | string>(permitOptions.PERMIT.value);
	const [loading, setLoading] = useState<boolean>(false);
	const [permit, setPermit] = useState<string>("");
	const [interconnection, setInterconnection] = useState<string>("");

	useEffect(() => {
		getPermitData();
		getInterconnectionData();
	}, []);

	const getPermitData = async () => {
		setLoading(true);
		try {
			const { data: permit } = await getPermitStatus();
			setPermit(permit?.status || "");

			// // if permit is accepted/completed, then open utility interconnection tab by default
			// if (permit.status === permitSteps.ACCEPTED.text) {
			// 	setTab(permitOptions.UTILITY.value);
			// }
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const getInterconnectionData = async () => {
		setLoading(true);
		try {
			const { data: interconnection } = await getInterconnectionStatus();
			setInterconnection(interconnection?.status || "");

			// if interconnection is present, then open utility interconnection tab by default
			if (interconnection?.status) {
				setTab(permitOptions.UTILITY.value);
			}
		} catch (error: any) {
			if (error === "No Interconnection request found!") {
				setInterconnection("");
			} else {
				toast.error(error);
			}
		}
		setLoading(false);
	};

	const isPermitSelected = tab === permitOptions.PERMIT.value;

	return (
		<SchedulingPageLayout loading={loading}>
			<Box display="flex" justifyContent="center" mt={50}>
				<CustomToggleButton
					isWhiteVariant
					value={tab}
					onChange={(val) => setTab(val)}
					options={Object.values(permitOptions)}
				/>
			</Box>

			{isPermitSelected ? (
				<PermitApplication moveToUtilityTab={() => setTab(permitOptions.UTILITY.value)} statusFromAPI={permit} />
			) : (
				<UtilityInterconnection statusFromAPI={interconnection} onContinue={onContinue} />
			)}
		</SchedulingPageLayout>
	);
};
export default PermitInterconnection;
