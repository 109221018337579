import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import CustomButton from "../Common/CustomButton";
import CustomDialog from "../Common/CustomDialog";
import CustomTextField from "../Common/CustomTextField";
import { allRoutes } from "../../Routes/AllRoutes";
import { useNavigate } from "react-router-dom";
import { IconSquareBox } from "../Login/loginStyles";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ScannedDataState } from "./ElectricBillUpload";
import { DescriptionOutlined } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
// import { monthlyDistributions } from "../../Utils/enums";

interface DataState {
	delivery: string;
	supply: string;
	total: string;
}

interface ErrorsState extends DataState {
	monthlyValues: string;
}

interface MonthlyValuesState {
	jan?: string;
	feb?: string;
	mar?: string;
	apr?: string;
	may?: string;
	jun?: string;
	jul?: string;
	aug?: string;
	sep?: string;
	oct?: string;
	nov?: string;
	dec?: string;
}

interface BillConfirmationDialogProps {
	open: boolean;
	onClose?: () => void;
	scannedData: ScannedDataState;
	onConfirmation: (data: ScannedDataState) => void;
}

const BillConfirmationDialog = ({ open, onClose, scannedData, onConfirmation }: BillConfirmationDialogProps) => {
	const navigate = useNavigate();
	const colors = useSelector(selectColors);

	const [data, setData] = useState<DataState>({ delivery: "", supply: "", total: "" });
	const [monthlyValues, setMonthlyValues] = useState<MonthlyValuesState>({
		jan: "",
		feb: "",
		mar: "",
		apr: "",
		may: "",
		jun: "",
		jul: "",
		aug: "",
		sep: "",
		oct: "",
		nov: "",
		dec: "",
	});
	const [errors, setErrors] = useState<ErrorsState>({ delivery: "", supply: "", total: "", monthlyValues: "" });
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (open) {
			setData({
				delivery: scannedData.delivery || "",
				supply: scannedData.supply || "",
				total: scannedData.total || "",
			});
			setMonthlyValues({
				jan: scannedData.monthlyValues?.find((item) => item.month?.toLowerCase() === "jan")?.value?.toString() || "",
				feb: scannedData.monthlyValues?.find((item) => item.month?.toLowerCase() === "feb")?.value?.toString() || "",
				mar: scannedData.monthlyValues?.find((item) => item.month?.toLowerCase() === "mar")?.value?.toString() || "",
				apr: scannedData.monthlyValues?.find((item) => item.month?.toLowerCase() === "apr")?.value?.toString() || "",
				may: scannedData.monthlyValues?.find((item) => item.month?.toLowerCase() === "may")?.value?.toString() || "",
				jun: scannedData.monthlyValues?.find((item) => item.month?.toLowerCase() === "jun")?.value?.toString() || "",
				jul: scannedData.monthlyValues?.find((item) => item.month?.toLowerCase() === "jul")?.value?.toString() || "",
				aug: scannedData.monthlyValues?.find((item) => item.month?.toLowerCase() === "aug")?.value?.toString() || "",
				sep: scannedData.monthlyValues?.find((item) => item.month?.toLowerCase() === "sep")?.value?.toString() || "",
				oct: scannedData.monthlyValues?.find((item) => item.month?.toLowerCase() === "oct")?.value?.toString() || "",
				nov: scannedData.monthlyValues?.find((item) => item.month?.toLowerCase() === "nov")?.value?.toString() || "",
				dec: scannedData.monthlyValues?.find((item) => item.month?.toLowerCase() === "dec")?.value?.toString() || "",
				// jan: scannedData.monthlyValues?.[0]?.toString() || "",
				// feb: scannedData.monthlyValues?.[1]?.toString() || "",
				// mar: scannedData.monthlyValues?.[2]?.toString() || "",
				// apr: scannedData.monthlyValues?.[3]?.toString() || "",
				// may: scannedData.monthlyValues?.[4]?.toString() || "",
				// jun: scannedData.monthlyValues?.[5]?.toString() || "",
				// jul: scannedData.monthlyValues?.[6]?.toString() || "",
				// aug: scannedData.monthlyValues?.[7]?.toString() || "",
				// sep: scannedData.monthlyValues?.[8]?.toString() || "",
				// oct: scannedData.monthlyValues?.[9]?.toString() || "",
				// nov: scannedData.monthlyValues?.[10]?.toString() || "",
				// dec: scannedData.monthlyValues?.[11]?.toString() || "",
			});
			setErrors({ delivery: "", supply: "", total: "", monthlyValues: "" });
		}
	}, [open]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setData((prevState) => ({ ...prevState, [name]: value }));
		setErrors((prevState) => ({ ...prevState, [name]: "" }));
	};

	const handleOnChangeMonthlyValues = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setMonthlyValues((prevState) => ({ ...prevState, [name]: value }));
		if (Object.values(monthlyValues)?.filter(Boolean)?.length >= 3) {
			setErrors((prevState) => ({ ...prevState, monthlyValues: "" }));
		}
	};

	const validateData = () => {
		const updatedErrors = { ...errors };

		updatedErrors.delivery = data.delivery ? "" : "This field cannot be empty";
		updatedErrors.supply = data.supply ? "" : "This field cannot be empty";
		updatedErrors.total = data.total
			? data.total === data.supply
				? "Total charges cannot be equal to supply charges. Please check if the information you entered is correct"
				: data.total === data.delivery
				? "Total charges cannot be equal to delivery charges. Please check if the information you entered is correct"
				: parseFloat(parseFloat(data.total?.toString() || "0")?.toFixed(1)) <
				  parseFloat(
						(parseFloat(data.supply?.toString() || "0") + parseFloat(data.delivery?.toString() || "0"))?.toFixed(1),
				  )
				? "Total charges cannot be less than the sum of the supply and delivery charges"
				: ""
			: "This field cannot be empty";
		updatedErrors.monthlyValues =
			Object.values(monthlyValues)?.filter(Boolean)?.length >= 4 ? "" : "Please fill at least apr fields";

		setErrors(updatedErrors);
		return !Object.values(updatedErrors).find(Boolean);
	};

	const handleConfirm = async () => {
		if (!validateData()) return;

		setLoading(true);
		try {
			const allMonths: Array<{ month: string; value: string | number; percentage?: number }> = [];

			Object.values(monthlyValues).forEach((value: string | number, idx) => {
				const month = Object.keys(monthlyValues)?.[idx];
				// const percentageIndex = Object.keys(monthlyDistributions).findIndex((item) =>
				// 	item.toLowerCase().includes(month),
				// );
				// let percentage = 0;
				// if (percentageIndex >= 0) percentage = Object.values(monthlyDistributions)?.[percentageIndex];
				allMonths.push({ value, month });
				// allMonths.push({ value, month, percentage });
				// if (value) allMonths.push({ value, month, percentage });
			});

			const updatedData: ScannedDataState = {
				delivery: data.delivery,
				supply: data.supply,
				total: data.total,
				monthlyValues: allMonths,
				// monthlyValues: Object.values(monthlyValues)?.filter(Boolean),
				jpgImage: scannedData.jpgImage,
				provider: scannedData.provider,
			};

			await onConfirmation(updatedData);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleCancel = () => {
		navigate(allRoutes.LOGIN);
		onClose?.();
	};

	return (
		<CustomDialog open={open} onClose={onClose} maxWidth={720}>
			<IconSquareBox
				sx={{
					border: `1px solid ${colors.border}`,
				}}
			>
				<DescriptionOutlined />
			</IconSquareBox>

			{/* <Typography variant="h2" my={16} textAlign="center">
				Confirm bill details
			</Typography> */}
			<Typography fontSize={16} textAlign="center" mt={16} mb={32} color={colors.textMid}>
				Hello there! We've scanned your utility bill to grab the total, delivery, and supply costs, along with monthly
				energy consumption. Our tool did its best, but sometimes it misses details. Please check the values below, fill
				in any missing info or fix errors. Total, delivery, and supply costs are a must, and a bit on monthly energy
				helps too. Your input ensures accurate results. Thanks for helping us fine-tune your energy data!
			</Typography>

			<CustomTextField
				autoFocus
				name="delivery"
				label="Total Delivery Services"
				type="number"
				bottom={16}
				value={data.delivery}
				onChange={handleOnChange}
				error={errors.delivery}
				startIcon={"$"}
			/>
			<CustomTextField
				name="supply"
				label="Total Supply Services"
				type="number"
				bottom={16}
				value={data.supply}
				onChange={handleOnChange}
				error={errors.supply}
				startIcon={"$"}
			/>
			<CustomTextField
				name="total"
				label="Total Charges"
				type="number"
				bottom={16}
				value={data.total}
				onChange={handleOnChange}
				error={errors.total}
				startIcon={"$"}
			/>

			<Typography mb={12} variant="h6">
				Electric Usage History (KWh)
			</Typography>
			<Box
				display="grid"
				gridTemplateColumns={{ xs: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
				gap={12}
				sx={{
					"& fieldset": errors.monthlyValues ? { borderColor: colors.error } : {},
				}}
			>
				<CustomTextField
					label="January"
					name="jan"
					type="number"
					value={monthlyValues.jan}
					onChange={handleOnChangeMonthlyValues}
				/>
				<CustomTextField
					label="February"
					name="feb"
					type="number"
					value={monthlyValues.feb}
					onChange={handleOnChangeMonthlyValues}
				/>
				<CustomTextField
					label="March"
					name="mar"
					type="number"
					value={monthlyValues.mar}
					onChange={handleOnChangeMonthlyValues}
				/>
				<CustomTextField
					label="April"
					name="apr"
					type="number"
					value={monthlyValues.apr}
					onChange={handleOnChangeMonthlyValues}
				/>
				<CustomTextField
					label="May"
					name="may"
					type="number"
					value={monthlyValues.may}
					onChange={handleOnChangeMonthlyValues}
				/>
				<CustomTextField
					label="June"
					name="jun"
					type="number"
					value={monthlyValues.jun}
					onChange={handleOnChangeMonthlyValues}
				/>
				<CustomTextField
					label="July"
					name="jul"
					type="number"
					value={monthlyValues.jul}
					onChange={handleOnChangeMonthlyValues}
				/>
				<CustomTextField
					label="August"
					name="aug"
					type="number"
					value={monthlyValues.aug}
					onChange={handleOnChangeMonthlyValues}
				/>
				<CustomTextField
					label="September"
					name="sep"
					type="number"
					value={monthlyValues.sep}
					onChange={handleOnChangeMonthlyValues}
				/>
				<CustomTextField
					label="October"
					name="oct"
					type="number"
					value={monthlyValues.oct}
					onChange={handleOnChangeMonthlyValues}
				/>
				<CustomTextField
					label="November"
					name="nov"
					type="number"
					value={monthlyValues.nov}
					onChange={handleOnChangeMonthlyValues}
				/>
				<CustomTextField
					label="December"
					name="dec"
					type="number"
					value={monthlyValues.dec}
					onChange={handleOnChangeMonthlyValues}
				/>
			</Box>
			{!!errors.monthlyValues && (
				<Typography mt={3} mx={14} color="error">
					{errors.monthlyValues}
				</Typography>
			)}

			<Box display="grid" gridTemplateColumns="1fr 1fr" gap={12} mb={12} mt={32}>
				<CustomButton
					variant={"outlined"}
					disabled={loading}
					onClick={handleCancel}
					sx={{ color: colors.text, haug: 50.8 }}
				>
					{/* {loading ? <CircularProgress size={20} color="inherit" /> : "Cancel"} */}
					Cancel
				</CustomButton>
				<CustomButton disabled={loading} onClick={handleConfirm}>
					{loading ? <CircularProgress size={20} color="inherit" /> : "Confirm"}
				</CustomButton>
			</Box>
		</CustomDialog>
	);
};

export default BillConfirmationDialog;
