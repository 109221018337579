import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/installation";

// =====|  Installation Service  |=====

const InstallationService = {
	getInstallationStatus: () => http.get(`${apiEndpoint}`, { headers: getAuthHeader() }),
	updateInstallationStatus: (data: FormData) =>
		http.put(`${apiEndpoint}/update-status`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),
	assignInstaller: () => http.post(`${apiEndpoint}/assign-installer`, {}, { headers: getAuthHeader() }),
	getAvailableSlotsOfInstaller: (date: string) =>
		http.get(`${apiEndpoint}/available-slots/${date}`, { headers: getAuthHeader() }),
	scheduleInstallation: (data: any) =>
		http.post(`${apiEndpoint}/schedule-installation`, data, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const getInstallationStatus = () => {
	return InstallationService.getInstallationStatus();
};

export const assignInstaller = () => {
	return InstallationService.assignInstaller();
};

export const getAvailableSlotsOfInstaller = (date: string) => {
	return InstallationService.getAvailableSlotsOfInstaller(date);
};

export const scheduleInstallation = (date: string, time: string) => {
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const payload = { date, time, timeZone };
	return InstallationService.scheduleInstallation(payload);
};
