import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/permit";

// =====|  Permit Application Service  |=====

const PermitApplicationService = {
	getPermitStatus: () => http.get(`${apiEndpoint}`, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const getPermitStatus = () => {
	return PermitApplicationService.getPermitStatus();
};
