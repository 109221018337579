import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useState } from "react";
import CustomButton from "./CustomButton";
import Loader from "./Loader";
import { uploadReportPDF } from "../../Services/reportService";

const PDFDownloader = ({
	elementId,
	fileName = "summary-report",
	buttonText,
	buttonVariant = "contained",
	isSummaryPage,
}: {
	elementId: string;
	fileName?: string;
	buttonText?: string;
	isSummaryPage?: boolean;
	buttonVariant?: "contained" | "outlined";
}) => {
	const [loading, setLoading] = useState(false);

	const captureScreenAndConvertToPDF = async () => {
		const element = document.getElementById(elementId) || document.body;
		// const element = <CustomButton>Text</CustomButton>;

		if (element) {
			setLoading(true);

			// Store the original styles
			const originalStyles = element.getAttribute("style");
			// Apply new styles for the screenshot
			element.style.paddingInline = "20px";
			if (!isSummaryPage) element.style.paddingBlock = "20px";

			const rightCol = document.getElementById("right-col");
			rightCol?.classList.add("only-for-pdf");

			const buttonsToHide = document.getElementsByClassName("buttons-to-hide-in-pdf");
			for (let i = 0; i < buttonsToHide.length; i++) {
				const button = buttonsToHide[i] as HTMLElement;
				button.style.display = "none";
			}

			const canvas = await html2canvas(element, { useCORS: true, imageTimeout: 0 });
			// const imgData = canvas.toDataURL("image/png");
			const imgData = canvas.toDataURL("image/jpeg", 0.3);
			// const imgData = canvas.toDataURL("image/jpeg");
			const canvasWidth = canvas.width;
			const canvasHeight = canvas.height;

			const multiplier = 0.264583;
			const pdf = new jsPDF({
				unit: "mm",
				format: [canvasWidth * multiplier, canvasHeight * multiplier],
				compress: true,
			});
			pdf.addImage(imgData, "JPEG", 0, 0, canvasWidth * multiplier, canvasHeight * multiplier, "", "MEDIUM");

			const pdfBlob = pdf.output("blob");
			const pdfFile = new File([pdfBlob], `${fileName}.pdf`, { type: "application/pdf" });

			const fileSizeInBytes = pdfFile.size;
			const fileSizeInKB = fileSizeInBytes / 1024; // Convert to kilobytes
			const fileSizeInMB = fileSizeInKB / 1024; // Convert to megabytes

			console.log(`PDF file size: ${fileSizeInKB.toFixed(2)} KB or ${fileSizeInMB.toFixed(2)} MB`);

			const formData = new FormData();
			if (isSummaryPage) {
				formData.append("summaryProposalPdfLink", pdfFile ?? "");
			} else {
				formData.append("billAnalysisPdfLink", pdfFile ?? "");
			}

			await uploadReportPDF(formData);

			// downloading PDF file
			pdf.save(`${fileName}.pdf`);

			// Revert to the original styles
			element.setAttribute("style", originalStyles || "");
			rightCol?.classList.remove("only-for-pdf");

			for (let i = 0; i < buttonsToHide.length; i++) {
				const button = buttonsToHide[i] as HTMLElement;
				button.style.display = "flex";
			}

			setLoading(false);
		}
	};

	return (
		<>
			<Loader open={loading} text="Generating PDF" />
			<CustomButton
				className="buttons-to-hide-in-pdf"
				variant={buttonVariant}
				fullWidth
				onClick={captureScreenAndConvertToPDF}
				disabled={loading}
			>
				{loading ? "Generating PDF..." : buttonText || "Download PDF"}
			</CustomButton>
		</>
	);
};
export default PDFDownloader;
