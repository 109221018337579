import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { toast } from "react-toastify";
import { storeFirebaseToken } from "./Services/notificationService";

// Your web app's Firebase configuration (replace with your actual config)
const firebaseConfig = {
	apiKey: "AIzaSyAnbDZvkm3qO_MRkVyXaiNpeFEoSVR3c1k",
	authDomain: "sungroupenergy-ce231.firebaseapp.com",
	projectId: "sungroupenergy-ce231",
	storageBucket: "sungroupenergy-ce231.appspot.com",
	messagingSenderId: "268847163908",
	appId: "1:268847163908:web:52ec8ef3bf3bd6d68e6351",
	measurementId: "G-GHFKS169D4",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export async function requestNotificationPermission() {
	console.log("Requesting permission...");
	await Notification.requestPermission()
		.then((permission) => {
			if (permission === "granted") {
				console.log("Notification permission granted.");
				// Get the token after permission is granted
				isSupported()
					.then((res) => {
						console.log("Supported browser: ", res);
						if (res) {
							retrieveToken();
						}
					})
					.catch((err) => {
						console.log("Browser not supported: ", err);
					});
			} else {
				console.log("Unable to get permission to notify.");
			}
		})
		.catch((error) => {
			console.error("An error occurred while requesting notification permission", error);
		});
}

function retrieveToken() {
	// Public VAPID key obtained from Firebase console
	const vapidKey = "BGG02lyCby3zbf6pRHFFrvNbVqOjEwiIw7Y3XZk_K6xu-e29nGgPQmWfvu_HzWEZqN76yX2gCoWWA7SjnhP4VGA";

	getToken(messaging, { vapidKey: vapidKey })
		.then((currentToken: any) => {
			if (currentToken) {
				console.log("Token retrieved:", currentToken);
				// Send the token to your server to subscribe this user to push messages
				sendTokenToServer(currentToken);
			} else {
				console.log("No registration token available. Request permission to generate one.");
			}
		})
		.catch((err: any) => {
			console.log("An error occurred while retrieving token. ", err);
		});
}

function sendTokenToServer(token: any) {
	console.log("Sending token to server...", token);
	// Here, you would typically make an API request to your server and pass the token
	storeFirebaseToken(token);
}

onMessage(messaging, (payload: any) => {
	console.log("Message received. ", payload);
	// Process your message as required
	if (payload.notification.body?.includes("Completed")) {
		toast.success(payload.notification.body, { autoClose: false });
	} else {
		toast.info(payload.notification.body, { autoClose: false });
	}
});
