import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/admin";

// =====|  Dashboard Service  |=====

const DashboardService = {
	updateAccountDetails: (userId: string, data: any) =>
		http.put(`${apiEndpoint}/add-password/${userId}`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),
};

export const updateAccountDetails = (userId: string, data: any) => {
	return DashboardService.updateAccountDetails(userId, data);
};
