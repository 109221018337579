export const allRoutes = {
	HOME: "/",
	ESTIMATE: "/estimate",
	SOLAR_REPORT: "/solar-report",
	LOGIN: "/login",
	SIGN_UP: "/register",
	RESET_PASSWORD: "/reset-password/:userId?/:token?",
	VERIFY_EMAIL: "/auth/verify-email/:userId?/:token?",
	WHY_CHOOSE_US: "/why-us",
	PROPOSAL_ACCEPTANCE: "/scheduling/proposal-acceptance",
	SITE_SURVEY: "/scheduling/site-survey",
	CAD_DESIGN: "/scheduling/cad-design",
	CONTRACT_SIGNING: "/scheduling/contract-signing",
	PERMIT_INTERCONNECTION: "/scheduling/permit-interconnection",
	INSTALLATION: "/scheduling/installation",
	TOWN_INSPECTION: "/scheduling/town-inspection",
	ACTIVATION: "/scheduling/activation",
	SCHEDULING: "/scheduling",
	PAYMENT: "/payment",
	ACCOUNT_SETTINGS: "/account-settings",
	ACCOUNT_CREATION : "/account-creation",
	USER_VIDEO_CALL: '/join'
};
