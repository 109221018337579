export const monthlyDistributions = {
	// TODO: get these values from configurableParameters get api
	January: 9.95,
	February: 8.98,
	March: 10.07,
	April: 7.28,
	May: 6.07,
	June: 4.61,
	July: 9.1,
	August: 14.56,
	September: 12.14,
	October: 3.64,
	November: 6.08,
	December: 7.52,
};

export const dashboards = {
	FRONTEND_CUSTOMER_APP: "fe-customer-app",
	INSTALLER_DASHBOARD: "installer-dashboard-app",
	ADMIN_DASHBOARD: "admin-dashboard-app",
};
