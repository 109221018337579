import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/town-inspection";

// =====|  TowInspection Service  |=====

const TowInspectionService = {
	getTowInspectionStatus: () => http.get(`${apiEndpoint}`, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const getTowInspectionStatus = () => {
	return TowInspectionService.getTowInspectionStatus();
};
