import { Box, Typography, Tooltip } from "@mui/material";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import { borderRadius } from "../../Utils/spacings";
import { formatNumber } from "../../Utils/utils";
import colors from "../../Utils/colors";

interface BarWithValueProps {
	height?: string | number;
	value?: number | string;
	isSolar?: boolean;
	subtext?: string;
}

interface DoubleBarsWithValueProps {
	height?: string | number;
	height2?: string | number;
	value?: number | string;
	value2?: number | string;
	// isSolar?: boolean;
	subtext?: string;
	subtext2?: string;
	color?: string;
	position?: string;
	heading?: string;
	totalValue?: number | string;
}

const ColorChip = ({ color }: { color: string }) => {
	return <Box sx={{ width: 10, height: 10, backgroundColor: color, borderRadius: borderRadius.lg }} />;
};

const Bar = ({ value = 0, subtext, height = 235, color = colors.primary, position }: DoubleBarsWithValueProps) => {
	return (
		<Tooltip
			arrow
			followCursor
			title={
				<Typography fontSize={12} display="flex" alignItems="center" gap={6}>
					<ColorChip color={color} />
					{subtext}: <b>${formatNumber(value)}</b>
				</Typography>
			}
		>
			<Box
				sx={{
					width: 97,
					position: position || "auto",
					bottom: 0,
					height,
					backgroundColor: color,
					borderTopLeftRadius: borderRadius.sm,
					borderTopRightRadius: borderRadius.sm,
				}}
			/>
		</Tooltip>
	);
};

const BarWithValue = ({ height = 235, value = 0, subtext, isSolar }: BarWithValueProps) => {
	const colors = useSelector(selectColors);
	return (
		<Box display="flex" alignItems="center" flexDirection="column">
			<Bar height={height} color={isSolar ? colors.primary : colors.gray} value={value} subtext={subtext} />
			<Typography variant="h5" fontSize={18} mt={8} mb={4} color={isSolar ? "primary" : "auto"}>
				${formatNumber(value)}
			</Typography>
			<Typography fontSize={12} color={isSolar ? "primary" : "auto"}>
				{subtext}
			</Typography>
		</Box>
	);
};

export default BarWithValue;

export const DoubleBarsWithValue = ({
	height = 235,
	height2 = 150,
	subtext,
	subtext2,
	heading,
	value = 0,
	value2 = 0,
	totalValue = 0,
}: DoubleBarsWithValueProps) => {
	const colors = useSelector(selectColors);
	return (
		<Box display="flex" alignItems="center" flexDirection="column">
			<Box display="flex" alignItems="center" flexDirection="column" position="relative" minHeight={height}>
				<Bar position="absolute" color={colors.primaryLight} subtext={subtext} value={value} height={height} />
				<Bar position="absolute" color={colors.primary} subtext={subtext2} value={value2} height={height2} />
			</Box>
			<Typography variant="h5" fontSize={18} mt={8} mb={4} color={"primary"}>
				${formatNumber(totalValue)}
			</Typography>
			<Typography fontSize={12} color={"primary"}>
				{heading}
			</Typography>
			{/* <Box display="flex" mt={24} gap={6} flexDirection="column">
				<Typography fontSize={12} display="flex" alignItems="center" gap={6}>
					<ColorChip color={colors.gray} />
					{subtext}: <b>${formatNumber(value)}</b>
				</Typography>
				<Typography fontSize={12} display="flex" alignItems="center" gap={6}>
					<ColorChip color={colors.primary} />
					{subtext2}: <b>${formatNumber(value2)}</b>
				</Typography>
			</Box> */}
		</Box>
	);
};
