import axios from "axios";
import http from "./httpService";
import { AppDispatch } from "../Redux/store";
import { resetUserState, saveUserState } from "../Redux/Slices/userSlice";
import { getAuthHeader } from "./userService";
import { resetRepresentativeState, saveRepresentativeState } from "../Redux/Slices/representativeSlice";

const apiEndpoint = "/profile";

// =====|  Profile Service  |=====

const ProfileService = {
	getProfile: () => http.get(`${apiEndpoint}`, { headers: getAuthHeader() }),
	getMyCurrentStep: () => http.get(`${apiEndpoint}/my-current-step`, { headers: getAuthHeader() }),
	updateProfile: (data: any) =>
		http.put(`${apiEndpoint}/edit-profile`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),
	verifyEmailOtp: (data: any) => http.post(`${apiEndpoint}/verifyOTP`, data, { headers: getAuthHeader() }),
	sendReferralLinkToFriend: (data: any) =>
		http.post(`${apiEndpoint}/refer-to-friend-link`, data, { headers: getAuthHeader() }),
	getAssignedRepresentative: () => http.get(`${apiEndpoint}/my-assigned-representative`, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const getProfile =
	() =>
	async (dispatch: AppDispatch): Promise<any | void> => {
		try {
			const { data }: any = await ProfileService.getProfile();
			console.log("Profile: ", data);

			const userData = getUserObjectForRedux(data?.UserInformation);
			dispatch(resetUserState());
			dispatch(saveUserState(userData));

			return userData;
		} catch (error) {
			console.error("getProfile (API): ", error);
			throw error;
		}
	};

export const updateProfile =
	(formData: any) =>
	async (dispatch: AppDispatch): Promise<any | void> => {
		try {
			const { data } = await ProfileService.updateProfile(formData);
			console.log("Updated Profile: ", data);

			const userData = getUserObjectForRedux(data?.User?.update_profile);
			dispatch(saveUserState(userData));

			return userData;
		} catch (error) {
			console.error("updateProfile (API): ", error);
			throw error;
		}
	};

export const getAssignedRepresentative =
	() =>
	async (dispatch: AppDispatch): Promise<any | void> => {
		try {
			const { data }: any = await ProfileService.getAssignedRepresentative();
			console.log("Representative: ", data);

			if (data._id) {
				const repData = {
					id: data?._id,
					name: data?.name,
					email: data?.email,
					phone: data?.phone,
					image: data?.image,
				};
				dispatch(saveRepresentativeState(repData));

				return repData;
			} else {
				dispatch(resetRepresentativeState());

				return null;
			}
		} catch (error) {
			console.error("getProfile (API): ", error);
			throw error;
		}
	};

export const getMyCurrentStep = () => {
	return ProfileService.getMyCurrentStep();
};

export const verifyEmailOtp = (data: any) => {
	return ProfileService.verifyEmailOtp(data);
};

export const sendReferralLinkToFriend = (data: any) => {
	return ProfileService.sendReferralLinkToFriend(data);
};

// TODO in future: move this google api and all other google apis in a separate googleApiService file
export const getGoogleProfile = async (accessToken: string) => {
	const { data } = await axios.get("https://www.googleapis.com/oauth2/v2/userinfo", {
		headers: { Authorization: `Bearer ${accessToken}` },
	});
	return data;
};

const getUserObjectForRedux = (user: any) => ({
	id: user?._id,
	sequentialId: user?.sequentialId,
	bill: user?.electricity_usage?.toString(),
	address: user?.address,
	name: user?.name,
	lastName: user?.lastName,
	email: user?.email,
	role: user?.role,
	password: user?.password,
	picture: user?.ImageUrl || "",
	phone: user?.phone_no,
	selectedPlan: user?.selectedPlan,
	postalCode : user?.postalCode,
	utilityCompanyName : user?.utilityCompany,
	...(user?.accessed_by ? {accessedBy : user?.accessed_by} : {}),

	...(user?.electricity_bill
		? {
				billData: {
					file: user?.electricity_bill,
					delivery: user?.deliveryCharges,
					supply: user?.supplyCharges,
					provider: user?.providerName,
					billCost: user?.uploadedBillCost,
				},
		  }
		: {}),

	...(user?.notificationPreferences
		? {
				notificationPreferences: {
					inApp: !!user?.notificationPreferences?.inApp,
					email: !!user?.notificationPreferences?.email,
					sms: !!user?.notificationPreferences?.sms,
				},
		  }
		: {}),

	...(user?.discounts
		? {
				discounts: {
					lowIncome: !!user?.discounts?.lowIncome,
					energyCommunity: !!user?.discounts?.energyCommunity,
					americanEquipment: !!user?.discounts?.americanEquipment,
				},
		  }
		: {}),
});
