import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Divider } from "@mui/material";
import { borderRadius } from "../../Utils/spacings";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";

interface OptionProps {
	icon?: any;
	text?: string | React.ReactNode;
	onClick?: () => void;
	isDivider?: boolean;
}

interface CustomMenuProps {
	anchorComponent?: any;
	options?: Array<OptionProps>;
}

const CustomMenu = ({ anchorComponent: AnchorComponent = Button, options = [] }: CustomMenuProps) => {
	const colors = useSelector(selectColors);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

	const handleClose = () => setAnchorEl(null);

	return (
		<div>
			<AnchorComponent
				id="custom-menu-anchor"
				aria-controls={open ? "custom-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
			/>

			<Menu
				id="custom-menu"
				aria-labelledby="custom-menu-anchor"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				BackdropProps={{
					style: { backdropFilter: "unset" },
				}}
				PaperProps={{ style: { borderRadius: borderRadius.sm, border: `1px solid ${colors.border}` } }}
			>
				{options.map((option: OptionProps, idx: number) =>
					option.isDivider ? (
						<Divider key={idx} style={{ marginTop: 12, marginBottom: 12 }} />
					) : (
						<MenuItem
							key={idx}
							onClick={() => {
								option?.onClick?.();
								handleClose();
							}}
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 9,
								px: 20,
								py: 8,
								minWidth: 226,
							}}
						>
							{!!option.icon && <option.icon style={{ width: 16, height: 16 }} />}
							{option.text}
						</MenuItem>
					),
				)}
			</Menu>
		</div>
	);
};

export default CustomMenu;
