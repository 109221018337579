import { Box, Divider, Theme, Typography, useMediaQuery } from "@mui/material";
import { borderRadius } from "../../Utils/spacings";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import { CheckRounded } from "@mui/icons-material";
import CustomButton from "../Common/CustomButton";
import { useEffect, useState } from "react";
import AcceptProposalDialog from "./AcceptProposalDialog";
import { decimalFreeNumber, formatNumber } from "../../Utils/utils";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import { selectUser } from "../../Redux/Slices/userSlice";
import { allRoutes } from "../../Routes/AllRoutes";
import { useNavigate } from "react-router-dom";
import { isAccessedByInstaller } from "../../Services/userService";

interface ItemData {
	heading: string;
	subHeading: string;
	disabled?: boolean;
	price: number;
	options: Array<string>;
	isHighlighted?: boolean;
	isSelected?: boolean;
	buttonOnClick: () => void;
	error?: string;
}
interface PlanCardProps {
	item: ItemData;
	removeSymbol?: boolean;
}

const Option = ({ text }: { text?: string }) => {
	const colors = useSelector(selectColors);
	return (
		<Box display="flex" gap={17}>
			<Box
				sx={{
					width: 24,
					height: 24,
					borderRadius: 12,
					backgroundColor: colors.primaryMidLight,
					color: colors.primary,
					fontSize: 16,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					fontWeight: 600,
				}}
			>
				<CheckRounded fontSize="inherit" />
			</Box>
			<Typography mt={2} color={colors.textMid}>
				{text}
			</Typography>
		</Box>
	);
};

const PlanCard = ({ item }: PlanCardProps) => {
	const colors = useSelector(selectColors);
	const isInstallerAccess = isAccessedByInstaller();

	return (
		// <Tooltip title={item.error || ""} placement="top" arrow>
		<Box
			sx={{
				padding: "40px 30px",
				borderRadius: borderRadius.md,
				border: `2px solid ${colors.lightGray}`,
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				gap: 18,
				boxShadow: item.isHighlighted ? "0px 20px 47px 0px #0000000D" : "unset",
				opacity: item.disabled && !item.error ? 0.5 : 1,
			}}
		>
			{item.error ? (
				<Typography textAlign="center" variant="h5" color={colors.text}>
					{item.error}
				</Typography>
			) : (
				<>
					<Typography variant="h3">{item.heading}</Typography>
					<Typography fontSize={16} color={colors.textMid}>
						{item.subHeading}
					</Typography>
					<Typography variant="h1" fontSize={42}>
						${formatNumber(decimalFreeNumber(item.price))}{" "}
						{
							item.heading === 'Cash' ? "" : <Typography component="span" variant="h6" color={colors.textMid}>
								/ Month
							</Typography>
						}



					</Typography>
					<Divider sx={{ mt: 19, mb: 12 }} />
					<Box display="flex" flexDirection="column" gap={16} mb={22}>
						{item.options?.map((item) => <Option key={item} text={item} />)}
					</Box>
					{!isInstallerAccess && (
						<CustomButton
							className="buttons-to-hide-in-pdf"
							sx={{
								mt: "auto",
								...(item.isHighlighted
									? {}
									: {
										backgroundColor: colors.primaryMidLight,
										color: "primary.main",
										"&:hover": { color: "white" },
									}),
							}}
							onClick={item.buttonOnClick}
							disabled={item.disabled}
						>
							{item.isSelected ? "Continue" : "Select Plan"}
						</CustomButton>
					)}
				</>
			)}
		</Box>
		// </Tooltip>
	);
};

const SelectPlan = ({ id, data, systemSize }: { id?: string; data?: any; systemSize?: number }) => {
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	const textSnippets = useSelector(selectAllTextSnippets);
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	const isInstallerAccess = isAccessedByInstaller();

	const [paymentsData, setPaymentsData] = useState<any>(data);
	const [showDialog, setShowDialog] = useState<boolean>(false);

	const [plan, setPlan] = useState<string>("");
	const [packagePrice, setPackagePrice] = useState<number | null>(null);

	useEffect(() => {
		setPaymentsData(data);
	}, [data]);

	const openDialog = (planName: string, packagePrice: number) => {
		setShowDialog(true);
		setPackagePrice(packagePrice);
		setPlan(planName);
	};
	const closeDialog = () => {
		setShowDialog(false);
		setPackagePrice(null);
		setPlan("");
	};

	const plans = [
		{
			heading: textSnippets["loan_plan_name"] || "Loan",
			subHeading: textSnippets["loan_plan_desc"] || "For customers who have the federal tax liability",
			price: paymentsData?.loanPayment || 0,
			buttonOnClick: user.selectedPlan
				? () => navigate(allRoutes.SCHEDULING)
				: () => openDialog("Loan", paymentsData?.loanPayment || 0), // navigate to schedule page
			options: [
				textSnippets["loan_plan_option_1"] || "25 year warranties Included",
				textSnippets["loan_plan_option_2"] || "Own the system from day 1",
				textSnippets["loan_plan_option_3"] || "Fixed for 25 years",
				textSnippets["loan_plan_option_4"] || "Best value plan",
				textSnippets["loan_plan_option_5"] || "Tax credits and local rebates",
			],
			isHighlighted: user.selectedPlan ? user.selectedPlan === "Loan" : true,
			disabled: !paymentsData?.loanPayment || (!!user.selectedPlan && user.selectedPlan !== "Loan"),
			isSelected: user.selectedPlan === "Loan",
		},
		{
			heading: textSnippets["lease_plan_name"] || "Lease",
			subHeading: textSnippets["lease_plan_desc"] || "For customer with low federal tax liability or appetite",
			price: paymentsData?.leasePayment || 0,
			buttonOnClick: user.selectedPlan
				? () => navigate(allRoutes.SCHEDULING)
				: () => openDialog("Lease", paymentsData?.leasePayment || 0),
			options: [
				textSnippets["lease_plan_option_1"] || "25 year warranties Included",
				textSnippets["lease_plan_option_2"] || "No day 1 system ownership",
				textSnippets["lease_plan_option_3"] || "Can be acquired after 5 years",
				textSnippets["lease_plan_option_4"] || "Bill increases by 1.9% per year",
				textSnippets["lease_plan_option_5"] || "Tax credits transferred  through savings ",
			],
			isHighlighted: user.selectedPlan === "Lease",
			isSelected: user.selectedPlan === "Lease",
			disabled: !paymentsData?.leasePayment || (!!user.selectedPlan && user.selectedPlan !== "Lease"),
			error:
				!paymentsData?.leasePayment && !!paymentsData?.CashOption && !!paymentsData?.loanPayment // only display error when lease payment is not available, while other are available
					? "Lease product may be available upon review"
					: "",
		},
		{
			heading: textSnippets["cash_plan_name"] || "Cash",
			subHeading: textSnippets["cash_plan_desc"] || "For customers who choose to avoid interest expenses",
			price: paymentsData?.CashOption || 0,
			buttonOnClick: user.selectedPlan
				? () => navigate(allRoutes.SCHEDULING)
				: () => openDialog("Cash", paymentsData?.CashOption || 0),
			options: [
				textSnippets["cash_plan_option_1"] || "25 year warranties Included",
				textSnippets["cash_plan_option_2"] || "Own the system from day 1",
				textSnippets["cash_plan_option_3"] || "Completely pay off solar",
				textSnippets["cash_plan_option_4"] || "Lowest lifetime expenses",
				textSnippets["cash_plan_option_5"] || "Tax credits and local rebates ",
			],
			isHighlighted: user.selectedPlan === "Cash",
			disabled: !paymentsData?.CashOption || (!!user.selectedPlan && user.selectedPlan !== "Cash"),
			isSelected: user.selectedPlan === "Cash",
		},
	];

	return (
		<Box id={id} mb={32}>
			<Box display="flex" flexDirection="column" alignItems="center" mt={{ xs: 24, sm: 60 }}>
				<Typography variant={isSmallScreen ? "h3" : "h2"} mb={10} textAlign="center">
					{textSnippets["select_your_plan"] || "Select Your Plan"}
				</Typography>
				<Typography fontSize={16} mb={24} maxWidth={768} textAlign="center">
					{textSnippets["select_your_plan_desc"] ||
						"Below is a list of your top three solar options. Note that additional options may be available. If you wish to discuss other options, schedule a chat with a specialist below."}
				</Typography>
			</Box>

			<Box display="grid" gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr 1fr" }} gap={{ xs: 24, sm: 30 }}>
				{plans.map((item: ItemData, idx: number) => (
					<PlanCard key={item.heading} item={item} removeSymbol={!idx} />
				))}
			</Box>
			{!isInstallerAccess && (
				<AcceptProposalDialog
					open={showDialog}
					onClose={closeDialog}
					plan={plan}
					packagePrice={packagePrice}
					systemPrice={data?.CashOption}
					systemSize={systemSize ?? 0}
				/>
			)}
		</Box>
	);
};

export default SelectPlan;
