import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomStepper from "../../../Common/CustomStepper";
import {
	CheckCircleOutline,
	EditCalendarOutlined,
	EventRepeatOutlined,
	HourglassEmptyOutlined,
	TravelExploreOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../../../Routes/AllRoutes";
import InformationBlock from "../../InformationBlock";
import { navbarHeight, tabsHeight } from "../../../../Utils/spacings";
import { useSelector } from "../../../../Redux/reduxHooks";
import { selectAllTextSnippets } from "../../../../Redux/Slices/textSnippetsSlice";
import { selectColors } from "../../../../Redux/Slices/generalSlice";

const steps = {
	REQUESTED: { text: "Requested", icon: <EventRepeatOutlined />, status: 0 },
	PENDING: { text: "Pending", icon: <HourglassEmptyOutlined />, status: 1 },
	IN_REVIEW: { text: "In review", icon: <EditCalendarOutlined />, status: 2 },
	ACCEPTED: { text: "Accepted", icon: <CheckCircleOutline />, status: 3 },
};

const TownInspection = ({
	moveToMeterReplacement,
	statusFromAPI,
}: {
	moveToMeterReplacement: () => void;
	statusFromAPI?: string;
}) => {
	const navigate = useNavigate();
	const colors = useSelector(selectColors);

	const [status, setStatus] = useState<null | any>(steps.REQUESTED.status);
	const textSnippets = useSelector(selectAllTextSnippets);

	useEffect(() => {
		const actualStatus =
			Object.values(steps).find((step) => step.text === statusFromAPI)?.status || steps.REQUESTED.status;
		setStatus(actualStatus);
	}, [statusFromAPI]);

	return (
		<>
			<InformationBlock
				icon={{
					iconComponent: TravelExploreOutlined,
					iconBg: colors.primary + "95",
					iconColor: "white",
					iconSize: 60,
					iconBorderColor: colors.primary + "45",
				}}
				title={
					<>
						{textSnippets["town_inspection_heading"] || "Town Inspection is"}{" "}
						<Typography
							variant="inherit"
							component="span"
							color={status === steps.ACCEPTED.status ? "success.dark" : "primary.main"}
						>
							{status === steps.REQUESTED.status
								? "requested"
								: status === steps.PENDING.status
								? "pending"
								: status === steps.IN_REVIEW.status
								? "in review"
								: "accepted"}
						</Typography>
					</>
				}
				subtitle={
					textSnippets["town_inspection_notification_text"] ||
					"You will be notified as soon as the design has completed. Please check again later."
				}
				buttons={[
					{
						buttonText:
							status === steps.ACCEPTED.status
								? textSnippets["town_inspection_continue_button"] || "Continue"
								: textSnippets["town_inspection_home_button"] || "Back to home",
						buttonOnClick: () =>
							status === steps.ACCEPTED.status ? moveToMeterReplacement?.() : navigate(allRoutes.PROPOSAL_ACCEPTANCE),
					},
				]}
				height={`calc(100vh - ${navbarHeight}px - ${tabsHeight}px  - 185px - 105px)`} // here (185px = height of CustomStepper) and (105px = height of CustomToggleButton)
			/>

			<Box pt={42} maxWidth={640} mx="auto" mb={{ xs: 32, sm: 70 }}>
				<CustomStepper steps={Object.values(steps)} activeStep={status} />
			</Box>
		</>
	);
};
export default TownInspection;
