export const borderRadius = {
	xs: 4,
	sm: 8,
	md: 16,
	lg: 20,
	xl: 24,
};

export const navbarHeight = 57;

export const tabsHeight = 70;

export const sidebarWidths = {
	xs: 290,
	sm : 400 , 
	md: 400,
	lg: 400,
  };