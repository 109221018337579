import { useLocation } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { useEffect, useRef, useState } from "react";
import GoogleConfirmationDialog from "./GoogleConfirmationDialog";
import { useSelector } from "../../Redux/reduxHooks";
import { BillDataState, selectUser } from "../../Redux/Slices/userSlice";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import {
	AppRegistrationOutlined,
	DescriptionOutlined,
	ElectricBoltOutlined,
	ForwardOutlined,
	SolarPowerOutlined,
	TrendingUpOutlined,
	TroubleshootOutlined,
	VerifiedUserOutlined,
} from "@mui/icons-material";
import CustomTabs, { scrollToElement } from "../Common/CustomTabs";
import WelcomeBlock from "./WelcomeBlock";
import ElectricBillUpload from "./ElectricBillUpload";
import YourSolar, { SolarSystemSpecs } from "./YourSolar";
import BillAnalysis from "./BillAnalysis";
import FinancialImpact from "./FinancialImpact";
import SelectPlan from "./SelectPlan";
import SolarReport from "../SolarReport/SolarReport";
import { toast } from "react-toastify";
import {
	getSolarSpecs,
	getFinancialImpactPayment,
	getFinancialImpactSavings,
	getPaymentOption,
	getSolarReport,
	updateUserSolarInfo,
} from "../../Services/reportService";
import Loader from "../Common/Loader";
import { verifyAddress } from "../../Services/addressService";
import { getSatelliteImage, getStreetViewImage } from "../../Utils/utils";
import PDFDownloader from "../Common/PDFDownloader";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import Footer from "../Footer/Footer";
import CustomButton from "../Common/CustomButton";

const WhyUs = () => {
	const { state }: any = useLocation();
	const user = useSelector(selectUser);
	const renderingFirstTime = useRef<boolean>(true);
	const satelliteImage = getSatelliteImage(user.address);
	const streetViewImage = getStreetViewImage(user.address);
	const textSnippets = useSelector(selectAllTextSnippets);
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
	const isSocialLogin = state?.isSocialLogin;

	const [googleDialog, setGoogleDialog] = useState<boolean>(false);
	const [displaySummary, setDisplaySummary] = useState<boolean>(true);
	const [displayMoreInfo, setDisplayMoreInfo] = useState<boolean>(false);
	const [displayBillBlock, setDisplayBillBlock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any>({});
	const [billData, setBillData] = useState<BillDataState | null>(null);
	const [incorrectAddress, setIncorrectAddress] = useState<boolean>(false);
	// Fetching Text Snippets From Redux

	const modules = {
		SUMMARY_PROPOSAL: {
			id: "summary-proposal",
			text: textSnippets["summary_proposal"] || "Summary Proposal",
			icon: <AppRegistrationOutlined />,
			alwaysActive: true,
		},
		WELCOME: { id: "welcome", text: textSnippets["welcome"] || "Welcome", icon: <SolarPowerOutlined /> },
		ELECTRIC_BILL: {
			id: "electric-bill-upload",
			text: textSnippets["electric_bill_upload"] || "Electric Bill Upload",
			icon: <ElectricBoltOutlined />,
		},
		BILL_ANALYSIS: {
			id: "bill-analysis",
			text: textSnippets["electric_bill_analysis"] || "Electric Bill Analysis",
			icon: <TroubleshootOutlined />,
		},
		YOUR_SOLAR: { id: "your-solar", text: textSnippets["your_solar"] || "Your Solar", icon: <DescriptionOutlined /> },
		FINANCIAL_IMPACT: {
			id: "financial-impact",
			text: textSnippets["financial_impact"] || "Financial Impact",
			icon: <TrendingUpOutlined />,
		},
		GUARANTEES: { id: "guarantees", text: textSnippets["guarantees"] || "Guarantees", icon: <VerifiedUserOutlined /> },
		NEXT_STEPS: { id: "next-steps", text: textSnippets["next_step"] || "Next Steps", icon: <ForwardOutlined /> },
	};

	const mobileModules = {
		SUMMARY_PROPOSAL: {
			id: "summary-proposal",
			text: textSnippets["summary_proposal"] || "Proposal",
			icon: <AppRegistrationOutlined />,
			// alwaysActive: true,
		},
		ELECTRIC_BILL: {
			id: "electric-bill-block",
			text: textSnippets["electric_bill_upload"] || "Electric Bill Upload",
			icon: <ElectricBoltOutlined />,
			// alwaysActive: true,
		},
		MORE_INFO: {
			id: "more-information",
			text: "More Information",
			icon: <DescriptionOutlined />,
			// alwaysActive: true,
		},
	};

	useEffect(() => {
		if (isSocialLogin) {
			openGoogleDialog();
		}
		getDataOfDashboard();
	}, [user.id, user.bill]);

	useEffect(() => {
		if (data?.solarReport && data?.paymentOptions) {
			const userSolarInfo = user.selectedPlan === 'Lease' 
				? {
					monthlyBillWithSolar: data?.solarReport.solarSavingMonth1Lease,
					solarSaving25Years: data?.solarReport.solarSavingYear25Lease,
					solarSystemPrice: data?.paymentOptions.CashOption
				  }
				: {
					solarSystemPrice: data?.paymentOptions.CashOption
				  };
	
			updateUserSolarInfo(userSolarInfo)
			  .then(() => console.log("User solar info updated"))
			  .catch((error) => console.error("Error updating user solar info", error));
		}
	}, [data, user.selectedPlan]);

	useEffect(() => {
		getUpdatedPaymentOptions();
	}, [user.discounts]);

	useEffect(() => {
		setDisplaySummary(true);
		setDisplayMoreInfo(false);
	}, [isSmallScreen]);

	const getDataOfDashboard = async () => {
		if (!user.id) return;

		// checking if the user has already uploaded bill
		if (user.billData) {
			const savedBillData = {
				file: user.billData?.file,
				delivery: user.billData?.delivery,
				supply: user.billData?.supply,
				provider: user.billData?.provider,
				billCost: user.billData?.billCost,
			};
			setBillData(savedBillData);
		}

		setLoading(true);
		try {
			const monthlyBill = user.bill || "";

			const { data: verificationStatus } = await verifyAddress(user.address || "");
			if (
				[
					"We are not providing services for this address yet. Please try again in future.",
					"Not a US address",
					"Something went wrong",
				].includes(verificationStatus)
			) {
				setIncorrectAddress(true);
				toast.warn("We are sorry, but we are currently not operating in your area. Please try again in a few months.");
			} else {
				setIncorrectAddress(false);
				// call this api first because this api generates the report on BE and data of that report is used in other APIs
				const { data: solarReport } = await getSolarReport(user.bill || "200", user.postalCode || "", user.selectedPlan === 'Lease' ? user.selectedPlan : "");
				const [{ data: solarSpecs }, { data: financialSavings }, { data: financialPayment }, { data: paymentOptions }] =
					await Promise.all([
						getSolarSpecs(monthlyBill, user.postalCode || ""),
						getFinancialImpactSavings(monthlyBill, user.postalCode || ""),
						getFinancialImpactPayment(monthlyBill, user.postalCode || ""),
						getPaymentOption(monthlyBill, user.postalCode || ""),
					]);

				setData({ solarReport, solarSpecs, financialSavings, financialPayment, paymentOptions });

			}

			if (renderingFirstTime.current) {
				renderingFirstTime.current = false;
			}

		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const getUpdatedPaymentOptions = async () => {
		if (!user.id) return;
		if (renderingFirstTime.current) return;

		try {
			const { data: paymentOptions } = await getPaymentOption(user.bill || "", user.postalCode || "");
			setData({ ...data, paymentOptions });
		} catch (error: any) {
			toast.error(error);
		}
	};

	const handleChangeTab = (elementId?: string) => {
		if (!elementId) return;

		const isSummaryTabClicked = modules.SUMMARY_PROPOSAL.id === elementId;
		const isBillTabClick = mobileModules.ELECTRIC_BILL.id === elementId;
		const isMoreInfoTabClicked = mobileModules.MORE_INFO.id === elementId;

		// if (isSmallScreen) {
		window.scrollTo(0, 0);
		// }

		if (isSummaryTabClicked) {
			if (!displaySummary) {
				setDisplaySummary(true);
				setDisplayMoreInfo(false);
				setDisplayBillBlock(false);
			}
		} else if (isBillTabClick) {
			if (!displayBillBlock) {
				setDisplayBillBlock(true);
				setDisplayMoreInfo(false);
				setDisplaySummary(false);
			}
		} else if (isMoreInfoTabClicked) {
			if (!displayMoreInfo) {
				setDisplayMoreInfo(true);
				setDisplaySummary(false);
				setDisplayBillBlock(false);
			}
		} else {
			if (displaySummary) setDisplaySummary(false);
			if (displayMoreInfo) setDisplayMoreInfo(false);
			if (displayBillBlock) setDisplayBillBlock(false);
		}
	};

	const handleUpdateBillData = (bill: any) => {
		setBillData(bill);
	};

	const handleSkipToPayment = async () => {
		// await handleChangeTab(mobileModules.MORE_INFO.id);

		// setTimeout(() => {
		scrollToElement(modules.GUARANTEES.id);
		// }, 10);
	};

	const openGoogleDialog = () => setGoogleDialog(true);
	const closeGoogleDialog = () => setGoogleDialog(false);

	return (
		<>
			<Navbar />
			<Loader open={loading} />

			{/* {incorrectAddress && (
				<Box
					sx={{
						position: "fixed",
						width: "100vw",
						height: "100vh",
						zIndex: 11,
						top: 0,
						WebkitBackdropFilter: "blur(12px)",
						backdropFilter: "blur(12px)",
						backgroundColor: "rgba(255, 255, 255, 0.6)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						gap: "24px",
						p: "12px",
					}}
				>
					<Typography variant="h4" maxWidth={450} textAlign="center">
						We are sorry, but we are currently not operating in your area. Please try again in a few months.
					</Typography>
					<CustomButton
						sx={{ minWidth: 300, marginBlock: 10 }}
						// onClick={() => navigate(allRoutes.WHY_CHOOSE_US)}
					>
						Update Information
					</CustomButton>
				</Box>
			)} */}

			<CustomTabs
				px={12}
				// options={Object.values(isSmallScreen ? mobileModules : modules)}
				options={Object.values(mobileModules)}
				onChange={handleChangeTab}
				value={
					// isSmallScreen
					// 	?
					displayMoreInfo
						? mobileModules.MORE_INFO.id
						: displaySummary
							? modules.SUMMARY_PROPOSAL.id
							: displayBillBlock
								? mobileModules.ELECTRIC_BILL.id
								: ""
					// :
					// displaySummary
					// ? modules.SUMMARY_PROPOSAL.id
					// : ""
				}
			/>

			<Box px={isSmallScreen ? 16 : 80}>
				{
					displaySummary ? (
						<>
							<SolarReport forDashboard dashboardData={data?.solarReport} />
							{/* {isSmallScreen && ( */}
							<>
								<SelectPlan
									id={modules.GUARANTEES.id}
									data={data?.paymentOptions}
									systemSize={data?.solarReport?.SystemSizeKW}
								/>
								{/* {isSmallScreen && ( */}
								<Box sx={{ width: "calc(100%)", mb: 32 }} maxWidth={650} mx="auto">
									<PDFDownloader
										isSummaryPage
										buttonText={textSnippets.download_proposal_button || "Download Summary Proposal"}
										elementId="summary-report"
									/>
								</Box>
								{/* )} */}
							</>
						</>
					) : // ) : isSmallScreen ? (
						displayMoreInfo ? (
							<Box id="summary-report">
								{isSmallScreen && (
									<>
										<Typography variant={isSmallScreen ? "h3" : "h2"} mt={{ xs: 32, sm: 60 }} mb={10}>
											{textSnippets["switch_solar_save"] || "Switch to solar and save big"}
										</Typography>
										<Typography fontSize={16} mb={32}>
											{textSnippets[" delivery_charge_info"] ||
												"There is no delivery charge for electricity produced on your roof in MA. Switch to solar and save big."}
										</Typography>
										<SolarSystemSpecs solarData={data.solarSpecs} />
									</>
								)}

								<YourSolar id={modules.YOUR_SOLAR.id} data={data.solarSpecs} satelliteImage={satelliteImage} />

								<WelcomeBlock
									id={modules.WELCOME.id}
									user={user}
									satelliteImage={satelliteImage}
									streetViewImage={streetViewImage}
									data={data.paymentOptions}
								/>

								<FinancialImpact
									id={modules.FINANCIAL_IMPACT.id}
									financialSavings={data.financialSavings}
									financialPayment={data.financialPayment}
								/>
								<SelectPlan
									id={modules.GUARANTEES.id}
									data={data?.paymentOptions}
									systemSize={data?.solarReport?.SystemSizeKW}
								/>
								<Box id={modules.NEXT_STEPS.id} />
								<Box mt={10} mb={{ xs: 32, sm: 106 }} maxWidth={650} mx="auto">
									<PDFDownloader
										buttonVariant="outlined"
										buttonText={textSnippets["download_proposal_button"] || "Download Proposal"}
										elementId="summary-report"
										fileName="bill-analysis"
									/>
								</Box>
							</Box>
						) : (
							<Box>
								{!user.selectedPlan && isSmallScreen && (
									<CustomButton sx={{ mt: 24 }} fullWidth onClick={handleSkipToPayment}>
										Select a Plan
									</CustomButton>
								)}
								<ElectricBillUpload
									id={modules.ELECTRIC_BILL.id}
									analysisModuleId={modules.BILL_ANALYSIS.id}
									onUpload={handleUpdateBillData}
									billData={billData}
									utilityCompanyName={user.utilityCompanyName}
								/>
								<BillAnalysis id={modules.BILL_ANALYSIS.id} billData={billData} solarReport={data?.solarReport} />

								<SelectPlan
									id={modules.GUARANTEES.id}
									data={data?.paymentOptions}
									systemSize={data?.solarReport?.SystemSizeKW}
								/>
							</Box>
						)
					//  : (
					// 	<Box id="summary-report">
					// 		<WelcomeBlock
					// 			id={modules.WELCOME.id}
					// 			user={user}
					// 			satelliteImage={satelliteImage}
					// 			streetViewImage={streetViewImage}
					// 			data={data.paymentOptions}
					// 		/>
					// 		<ElectricBillUpload
					// 			id={modules.ELECTRIC_BILL.id}
					// 			analysisModuleId={modules.BILL_ANALYSIS.id}
					// 			onUpload={handleUpdateBillData}
					// 			billData={billData}
					// 			utilityCompanyName={user.utilityCompanyName}
					// 		/>
					// 		<BillAnalysis id={modules.BILL_ANALYSIS.id} billData={billData} solarReport={data?.solarReport} />
					// 		<YourSolar id={modules.YOUR_SOLAR.id} data={data.solarSpecs} satelliteImage={satelliteImage} />
					// 		<FinancialImpact
					// 			id={modules.FINANCIAL_IMPACT.id}
					// 			financialSavings={data.financialSavings}
					// 			financialPayment={data.financialPayment}
					// 		/>
					// 		<SelectPlan
					// 			id={modules.GUARANTEES.id}
					// 			data={data?.paymentOptions}
					// 			systemSize={data?.solarReport?.SystemSizeKW}
					// 		/>
					// 		<Box id={modules.NEXT_STEPS.id} />
					// 		<Box mt={10} mb={106} maxWidth={650} mx="auto">
					// 			<PDFDownloader
					// 				buttonVariant="outlined"
					// 				buttonText={textSnippets["download_proposal_button"] || "Download Proposal"}
					// 				elementId="summary-report"
					// 				fileName="bill-analysis"
					// 			/>
					// 		</Box>
					// 	</Box>
					// )
				}
			</Box>

			<Footer onClick={handleSkipToPayment} isPlanSelected={!!user.selectedPlan} />
			{/* {!user.selectedPlan && isSmallScreen && <Footer onClick={handleSkipToPayment} />} */}

			<GoogleConfirmationDialog open={googleDialog} onClose={closeGoogleDialog} />
		</>
	);
};

export default WhyUs;
