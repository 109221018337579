import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { BorderedBox } from "./whyUsStyles";
import CustomPieChart, { DataSetProps } from "../Common/Charts/CustomPieChart";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";
import { useEffect, useState } from "react";
import { BillDataState, selectUser } from "../../Redux/Slices/userSlice";
import { toast } from "react-toastify";
import { getBillAnalysis } from "../../Services/reportService";
import { formatNumber } from "../../Utils/utils";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";

interface ChartBlockProps {
	fontSize?: number;
	size?: number | string;
	title?: string;
	dataSet?: Array<DataSetProps>;
	isLargeOne?: boolean;
}

const ChartBlock = ({ dataSet = [], title = "", size = 286, fontSize = 20, isLargeOne }: ChartBlockProps) => {
	const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	return (
		<Box display="flex" flexDirection="column" gap={{ xs: 18, sm: 32 }}>
			<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
				<CustomPieChart
					size={isMobileView ? (isLargeOne ? 300 : 260) : size}
					fontSize={isMobileView ? 20 : fontSize}
					dataSets={dataSet}
				/>
			</Box>
			<Typography variant="h4" textAlign="center" mt="auto">
				{title}
			</Typography>
		</Box>
	);
};

const BillAnalysis = ({
	id,
	billData,
	solarReport,
}: {
	id?: string;
	billData?: BillDataState | null;
	solarReport?: any;
}) => {
	const colors = useSelector(selectColors);
	const user = useSelector(selectUser);
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any>({});
	const textSnippets = useSelector(selectAllTextSnippets);

	useEffect(() => {
		handleBillAnalysis();
	}, [billData?.delivery, billData?.supply, solarReport]);

	const handleBillAnalysis = async () => {
		if (!billData) return;
		if (!solarReport) return;

		setLoading(true);
		try {
			const monthlyBill = user.bill || "";
			const deliveryService = billData.delivery ?? 67.53;
			const supplyCharges = billData.supply ?? 65.92;
			const uploadedBillCost =
				billData.billCost ?? parseFloat(deliveryService?.toString()) + parseFloat(supplyCharges?.toString()) ?? 0;

			const { data: billAnalysis } = await getBillAnalysis(
				monthlyBill,
				deliveryService,
				supplyCharges,
				uploadedBillCost,
				user.postalCode || "",
			);

			setData(billAnalysis);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	return (
		<Box id={id} mb={{ xs: 32, sm: 0 }}>
			<Typography variant="h2" mt={{ xs: 32, sm: 60 }} mb={{ xs: 10, sm: 10 }}>
				{textSnippets["utility_bill_analysis"] || "Utility Bill Analysis"}
			</Typography>
			<Typography fontSize={16} mb={32}>
				{textSnippets["electricity_expense_info"] || "What to know about your electricity expense with the grid"}
			</Typography>
			{!billData || (billData && loading) ? (
				<BorderedBox p={{ xs: "100px 24px", sm: "180px 40px", border: `1px solid ${colors.border}` }}>
					<Typography textAlign="center" color={colors.textMid} fontSize={16}>
						{loading ? "Analyzing your bill..." : "Your analysis will be available after you upload your electric bill"}
					</Typography>
				</BorderedBox>
			) : (
				<BorderedBox p={{ xs: 10, sm: 40, border: `1px solid ${colors.border}`, }}>
					<Typography
						variant={isSmallScreen ? "h6" : "h4"}
						mb={{ xs: 32, sm: 65 }}
						textAlign={{ xs: "center", sm: "left" }}
					>
						{textSnippets["25yr_do_nothing_cost"] || "Your 25 year cost of doing nothing"}
						<Typography component="span" variant="inherit" color="error.dark" ml={{ xs: 10, sm: 20 }}>
							${formatNumber(data?.costOfElectricityWithoutSolar20year || 0)}
						</Typography>
					</Typography>

					<Box
						display="flex"
						alignItems="stretch"
						justifyContent="center"
						gap={{ xs: 40, sm: 120 }}
						flexDirection={{ xs: "column", sm: "row" }}
					>
						<ChartBlock
							size={250}
							fontSize={20}
							dataSet={[
								{ label: "Delivery", value: data?.deliveryServiceYear1 || 50 },
								{ label: "Supply", value: data?.supplyYear1 || 50 },
							]}
							title={textSnippets["first_year_heading"] || "First Year"}
						/>
						<ChartBlock
							isLargeOne
							size={469}
							fontSize={28}
							dataSet={[
								{ label: "Delivery", value: data?.deliveryServiceYear20 || 6000 },
								{ label: "Supply", value: data?.supplyYear20 || 6000 },
							]}
							title={textSnippets["over_25_years_heading"] || "Over 25 Years"}
						/>
					</Box>
				</BorderedBox>
			)}
		</Box>
	);
};

export default BillAnalysis;
