import { Box } from "@mui/material";

const Asterisk = () => {
	return (
		<Box component="span" sx={{ color: "error.main" }}>
			{" "}
			*
		</Box>
	);
};

export default Asterisk;
