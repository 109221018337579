import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { GrayTextBlock, RoundedImage, TwoColumnGrid } from "./whyUsStyles";
import { HomeImage } from "../../Utils/Images";
import { useRef } from "react";
import { useSelector } from "../../Redux/reduxHooks";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import { selectColors } from "../../Redux/Slices/generalSlice";

const OurSolutions = ({ satelliteImage, streetViewImage }: { satelliteImage?: any; streetViewImage?: any }) => {
	const rightColumnRef = useRef<HTMLDivElement | null>(null);
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
	const colors = useSelector(selectColors);

	const rightColumnHeight = rightColumnRef.current?.offsetHeight || 500;
	const bottomImageHeight = isSmallScreen ? "467px" : rightColumnHeight - 240 - 20;
	const textSnippets = useSelector(selectAllTextSnippets);
	const ourReach = [
		{
			title: textSnippets["control_heading"] || `Lower Cost`,
			description:
				textSnippets["control_description"] ||
				`As you'll see from your proposal, switching to solar with no out-of-pocket cost comes with huge savings from day one`,
		},
		{
			title: textSnippets["environmental_impact_heading"] || `Tax Incentives`,
			description:
				textSnippets["environmental_impact_description"] ||
				`The Federal Investment Tax Credit for solar starts at 30% and can go as high as 60% for eligible customers. States and utilities may offer incentives too`,
		},
		{
			title: textSnippets["savings_stability_heading"] || `Control & Stability`,
			description:
				textSnippets["savings_stability_description"] ||
				`Don’t “rent” power from the grid on a variable contract for life. Switch to solar and take control of your electricity bill`,
		},
		{
			title: textSnippets["home_value_heading"] || `Increase Home Value`,
			description:
				textSnippets["home_value_description"] ||
				`According to Zillow, homes that have solar sell for 4% more than non-solar homes`,
		},
	];

	return (
		<TwoColumnGrid mt={isSmallScreen ? 24 : 32}>
			{!isSmallScreen && (
				<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 20, order: -1 }}>
					<RoundedImage src={streetViewImage || HomeImage} style={{ height: bottomImageHeight, flexGrow: 1 }} alt="" />
				</Box>
			)}
			<Box ref={rightColumnRef}>
				<Typography variant={isSmallScreen ? "h3" : "h1"} fontWeight={500} mb={19}>
					{textSnippets["solar_benefits_track"] || "You are on track for some exciting solar benefits"}
				</Typography>

				{ourReach.map((sol, idx) => (
					<GrayTextBlock
						mb={idx !== ourReach.length - 1 ? 16 : 0}
						key={sol.title}
						sx={{ backgroundColor: colors.primaryExtraLight }}
					>
						<Typography variant="h5">{sol.title}</Typography>
						<Typography fontSize={16}>{sol.description} </Typography>
					</GrayTextBlock>
				))}
			</Box>
		</TwoColumnGrid>
	);
};

export default OurSolutions;
