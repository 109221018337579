import { Chart } from "react-chartjs-2";
import { Typography } from "@mui/material";
import { formatNumber } from "../../../Utils/utils";

export interface DataSetProps {
	label?: string;
	value?: string | number;
	color?: string;
}

interface CustomDoughnutChartProps {
	displayingValue?: number;
	size?: number | string;
	dataSets?: Array<DataSetProps>;
}

const CustomDoughnutChart = ({ dataSets, displayingValue = 20, size = "100%" }: CustomDoughnutChartProps) => {
	const labels = dataSets?.map((item) => item.label ?? "");
	const values = dataSets?.map((item) => item.value ?? 0) || [];
	const bgColors = dataSets?.map((item) => item.color) || [];

	return (
		<div
			style={{
				width: size,
				position: "relative",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Typography variant="h2" sx={{ position: "absolute" }}>
				{formatNumber(displayingValue)}%
			</Typography>
			<Chart
				type="doughnut"
				data={{
					datasets: [
						{
							data: values,
							backgroundColor: bgColors,
							borderWidth: 0,
						},
					],
					labels: labels,
				}}
				options={{
					responsive: true,
					cutout: "75%",
					maintainAspectRatio: true,
					plugins: {
						legend: { display: false },
						title: { display: false },
						tooltip: {
							enabled: true,
							callbacks: {
								label: (context) => {
									const value = context.formattedValue;
									return `  ${formatNumber(value)}%`;
								},
							},
						},
					},
				}}
				style={{ maxWidth: "100%" }}
			/>
		</div>
	);
};
export default CustomDoughnutChart;
