import { StyledFooter } from "./footerStyles";
import CustomButton from "../Common/CustomButton";
import { Typography } from "@mui/material";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";

const Footer = ({ onClick, isPlanSelected }: { onClick: () => void; isPlanSelected: boolean }) => {
	const colors = useSelector(selectColors);

	return (
		<StyledFooter
			position="static"
			sx={{
				color: colors.text,
				borderTop: `1px solid ${colors.border}`,
			}}
		>
			{!isPlanSelected && <Typography color={colors.gray}>You may now select a plan</Typography>}
			<CustomButton sx={{ maxWidth: { sm: "100%", md: "max-content" } }} onClick={onClick} fullWidth>
				{isPlanSelected ? "View Project" : "Skip to plan selections"}
			</CustomButton>
		</StyledFooter>
	);
};

export default Footer;
