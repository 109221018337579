import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Call,
  CallControls,
  CallingState,
  SpeakerLayout,
  StreamCall,
  StreamTheme,
  StreamVideo,
  StreamVideoClient,
  useCallStateHooks,
} from '@stream-io/video-react-sdk';
import '@stream-io/video-react-sdk/dist/css/styles.css';
import '../../App.css';
import { allRoutes } from '../../Routes/AllRoutes';
import { useSelector } from '../../Redux/reduxHooks';
import { selectVideoClientState } from '../../Redux/Slices/videoSlice';
import Loader from '../Common/Loader';

interface VideoUser {
  id: string;
  name: string;
  image: string;
}

const UserVideoCall: React.FC = () => {
  const videoSlice = useSelector(selectVideoClientState)
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const callId = urlParams.get('call_id');
  const callType = urlParams.get('call_type') || 'default';

  const [client, setClient] = useState<StreamVideoClient | null>(null);
  const [call, setCall] = useState<Call | null>(null);
  const isInitialized = useRef(false);

  const anonUserName = localStorage.getItem("anonUserName")

  useEffect(() => {
    const initializeCall = async () => {
      if (isInitialized.current || !callId) return; // Ensure callId is not null
      isInitialized.current = true;

      const apiKey = videoSlice.apiKey || ''; // Replace with your actual API key
      const token = videoSlice.token || ''; // Replace with the user's token
      const user: VideoUser = videoSlice.user ||  {
        id: '', // Replace with the user's ID
        name: anonUserName? anonUserName: '', // Replace with the user's name
        image: `https://getstream.io/random_svg/?id=user&name=${anonUserName}`,
      };

      const streamClient = new StreamVideoClient({ apiKey, user, token });
      const streamCall = streamClient.call(callType, callId);

      try {
        await streamCall.join();
        setClient(streamClient);
        setCall(streamCall);

        streamCall.on('call.ended', () => {
          navigate(allRoutes.ESTIMATE);
        });
      } catch (err) {
        console.error('Failed to join the call', err);
      }
    };

    initializeCall();

    return () => {
      if (call) {
        call.leave().catch((err) => {
          console.error('Failed to leave the call', err);
        });
        setCall(null);
      }
      setClient(null);
    };
  }, [callId, callType, navigate]);

  if (!client || !call) {
    return <Loader />;
  }

  return (
    <StreamVideo client={client}>
      <StreamCall call={call}>
        <UILayout />
      </StreamCall>
    </StreamVideo>
  );
};

function UILayout(): JSX.Element {
  const { useCallCallingState } = useCallStateHooks();
  const callingState = useCallCallingState();
  const navigate = useNavigate();

  useEffect(() => {
    if (callingState === CallingState.LEFT) {
      navigate(allRoutes.ESTIMATE);
    }
  }, [callingState, navigate]);

  if (callingState !== CallingState.JOINED) {
    return  <Loader />;
  }

  return (
    <StreamTheme>
      <SpeakerLayout participantsBarPosition="bottom" />
      <CallControls />
    </StreamTheme>
  );
}

export default UserVideoCall;
