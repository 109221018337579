import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/future-customers";

// =====|  Future Customers Service  |=====

const FutureCustomersService = {
	storeFutureCustomerInformation: (payload: any) =>
		http.post(`${apiEndpoint}/store-contact-info`, payload, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const storeFutureCustomerInformation = (payload:any) => {
	return FutureCustomersService.storeFutureCustomerInformation(payload);
};
