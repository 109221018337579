import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { dummyInstallerPicture } from "../../Components/Scheduling/SubPages/Installation";

export interface RepresentativeState {
	id?: number | string;
	name: string;
	email?: string;
	phone?: string;
	image?: string;
}

const initialState: RepresentativeState = {
	id: "",
	name: "",
	email: "",
	phone: "",
	image: "",
};

export const representativeSlice = createSlice({
	name: "representative",
	initialState: initialState,
	reducers: {
		saveRepresentativeState: (state, action: PayloadAction<RepresentativeState>) => {
			state.id = action.payload.id;
			state.name = action.payload.name;
			state.email = action.payload.email;
			state.phone = action.payload.phone;
			state.image = action.payload.image || dummyInstallerPicture;
		},
		resetRepresentativeState: (state) => {
			state.id = initialState.id;
			state.name = initialState.name;
			state.email = initialState.email;
			state.phone = initialState.phone;
			state.image = initialState.image;
		},
	},
});

export const { saveRepresentativeState, resetRepresentativeState } = representativeSlice.actions;

export const selectRepresentative = (state: RootState) => state.representative;

export default representativeSlice.reducer;
