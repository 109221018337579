import { Avatar } from "@mui/material";
import { useRef } from "react";
import { toast } from "react-toastify";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { useSelector } from "../../Redux/reduxHooks";

interface ImageUploaderProps {
	onUpdate: any;
	imageFile?: any;
	size?: string | number;
}

const ImageUploader = ({ onUpdate, imageFile, size = 129 }: ImageUploaderProps) => {
	const colors = useSelector(selectColors);
	const inputRef = useRef<any>(null);

	const handleImageUploader = (event: any) => {
		const selectedImage = event.target.files[0];

		if (!selectedImage) return;
		if (selectedImage?.size > 1572864) {
			// Max image size set to 1.5MB, 1,048,576 * 1.5 = 1,572,864 Bytes
			// 1MB = 1,048,576 Bytes
			toast.error("The selected image exceeds the maximum allowed size. Please choose a smaller image file.");
		} else {
			onUpdate(selectedImage);
		}
		event.target.value = null; // resetting the value of input
	};

	return (
		<div>
			<input
				ref={inputRef}
				type="file"
				name="myImage"
				accept="image/png, image/jpeg, image/jpg"
				onChange={handleImageUploader}
				style={{ display: "none" }}
			/>
			<Avatar
				sx={{ cursor: "pointer", width: size, height: size, border: `1px solid ${colors.border}` }}
				src={typeof imageFile === "string" ? imageFile : URL.createObjectURL(imageFile)}
				onClick={() => inputRef?.current?.click()}
			/>
		</div>
	);
};

export default ImageUploader;
